import styled from "styled-components";



interface CategoryCardInterface {
    onClick : () => void,
    img: string,
    name : string,
    dd : string 
}

const CategoryCard = (
    {onClick, img ,name, dd}: CategoryCardInterface) => {

    return (
        <SCategoryCard onClick={onClick}>
            <dl>
            <dt>
                <figure><img src={`./images/${img}`} /></figure>
            </dt>
            <dd>
                <strong>{name}</strong>
                <p>{dd}</p>
            </dd>
        </dl>
        </SCategoryCard>
    )
}

const SCategoryCard = styled.div`
    dl{
        display: flex;
        justify-content: space-between;
        dt{
            flex-basis: 45%;
        }
        dd{
            flex-basis: 55%;
            padding: 4px;
            strong{
                display: block;
                margin-bottom: 5px;
            }
            p{
                font-size: 1.0rem;
            }
        }
        figure{
            position: relative;
            width: 100%;
            padding-bottom: 65%;
            overflow: hidden;
            background: #fff;
            img{
                position: absolute;
            }
        }
    }
    cursor: pointer;
    background: #fff;
    border: solid 1px ${props => props.theme.colors.grayB};
    &:hover{
        border: solid 1px ${props => props.theme.colors.blue};
    }
`

export default CategoryCard;