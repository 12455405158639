import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"


const SortNav = ({handleUpdate, type}:{handleUpdate:()=>void, type: string}) => {
    
    const location = useLocation();
    const navivate =useNavigate();

    const addQueryParam = (sort:string) => {
        const currentUrlParams = new URLSearchParams(location.search); // 現在のURLのクエリパラメータを取得
        currentUrlParams.set('sort', sort); // `sort=price_upper`をセットまたは上書き
    
        // URLを更新 (現在のパスに新しいクエリパラメータを付与)
        navivate(`${location.pathname}?${currentUrlParams.toString()}`);
        handleUpdate()
    };

    return (
        <SSortNav>
            <span>並び替え</span> 
            {
                 type === 'chintais' &&
                 <dl>
                    <dd>家賃</dd>
                    <dt>
                        <i className="asc" onClick={() => {addQueryParam('price_asc')}}></i>
                        <i className="desc" onClick={() => {addQueryParam('price_desc')}}></i>
                    </dt>
                    <dd>間取り</dd>
                    <dt>
                        <i className="asc" onClick={() => {addQueryParam('layout_asc')}}></i>
                        <i className="desc" onClick={() => {addQueryParam('layout_desc')}}></i>
                    </dt>
                </dl>
            }
             {
                 type === 'baibai' &&
                 <dl>
                    <dd>価格</dd>
                    <dt>
                        <i className="asc" onClick={() => {addQueryParam('price_asc')}}></i>
                        <i className="desc" onClick={() => {addQueryParam('price_desc')}}></i>
                    </dt>
                </dl>
            }
            {
                type === 'land' &&
                <dl>
                    <dd>価格</dd>
                    <dt>
                        <i className="asc" onClick={() => {addQueryParam('price_asc')}}></i>
                        <i className="desc" onClick={() => {addQueryParam('price_desc')}}></i>
                    </dt>
                </dl>
            }
            
        </SSortNav>
    )
}

const SSortNav = styled.nav`
    font-size: ${props => props.theme.fontSize.sortNav};
    padding: 20px 0 20px;
    display: flex;
    align-items: center;
    
    dl{
       margin-left: 30px;
       display: flex;
       align-items: center;
       dd,dt{
        margin-right: 10px;
       }
       dt{
        margin-right: 20px;
       }
    }
    i{
        cursor: pointer;
        &:hover{
            color: ${props => props.theme.colors.red}
        }
    }
    .asc{
        display: inline-block;
        margin-right: 5px;
        
        &::after{
            content: '▲';
            font-size: 2.4rem;
        }
       
    }
    .desc{
        display: inline-block;
        &::after{
            content: '▼';
            font-size: 2.4rem;
        }
       
    }
`

export default SortNav