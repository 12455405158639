import { useState } from "react"
import { useForm } from "react-hook-form"
import SubmitFrom from "src/Components/Atoms/Form/submit"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import { InfoForm } from "src/Components/Organisms/InfoForm"
import { InfoFormConfirm } from "src/Components/Organisms/InfoForm/confirm"
import { InfoFormSend } from "src/Components/Organisms/InfoForm/send"
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate"
import { fetchContact } from "src/Data/fetchContact"
import { FormStateType, InfoFromInerface } from "src/Types/form.d"
import styled from "styled-components"


export const InfomationPage = () => {
    document.title = 'お問合せフォーム | 丸一不動産 宮崎県日向市の総合不動産会社'

    const { register, formState:{ errors }, watch, handleSubmit } = useForm<InfoFromInerface>()
    const [ formState, setFormState ] = useState<FormStateType>('input')
    const [ input, setInput ] = useState<InfoFromInerface>()

    const onFormSubmit = (currentInput) => {
        window.scroll(0,0)
        switch(formState){
            case 'input':
                setFormState('confirm')
                setInput(currentInput)
                break;
            case 'confirm' : 
                fetchContact(currentInput).then((res) => {
                    setFormState('send')
                })
                break;
        }
    }

    const confirmCancel = () => {
        setFormState('input')
    }
    return (
        <MaruichiTemplate>
            <div className="contentsHeader--inner">
                <BreadcrumbNav 
                    items={[
                        { name:`お問い合わせフォーム`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                <ContentsTitle>お問い合わせフォーム</ContentsTitle>
            </div>  
            <SInfomation>
                <div className="info--inner">
                { formState === 'input' &&
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <InfoForm 
                        register={register}
                        errors={errors}
                        watch={watch}
                    />
                </form>
                }
                {
                    formState === 'confirm' &&
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                    <InfoFormConfirm 
                        input={input}
                        cancel={confirmCancel}
                    />
                    </form>
                }
                {
                     formState === 'send' &&
                     <InfoFormSend/>
                }
            </div>  
            </SInfomation>
        </MaruichiTemplate>
        
    )  
}

const SInfomation = styled.div`
    .info--inner{
        background: ${props => props.theme.colors.grayC};
        padding: 40px 20px;
        margin-bottom: 40px;
    }
`