import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Checkbox } from "src/Components/Atoms/Form/checkbox";
import SearchFormTemplate from "src/Components/Templates/searchFromTemplate";
import { fetchLayouts } from "src/Data/fetchSearchParam";
import { SearchFromInterface } from "src/Types/form.d";
import {  LayoutType } from "src/Types/layout.d";
import { SearchFromArea } from "./area";
import SubmitFrom from "src/Components/Atoms/Form/submit";
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectValues, SelectForm } from "src/Components/Atoms/Form/select";
import { ChintaiCategorySearchForm } from "./chintaiCategory";

export const SearchFormChintai = ({handleUpdate, queryParams, formReset=false}:{
        handleUpdate:()=>void
        queryParams : URLSearchParams,
        formReset ?: boolean
    }) => {

    const location = useLocation();
        
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm<SearchFromInterface>({
        defaultValues: {}
    });

    const [layouts ,setLayout] = useState<LayoutType[]>()
   
    useEffect(() => {
        fetchLayouts('').then((res) => {
            if(res.respons === 'success') setLayout(res.data)
        })
    },[])  

    useEffect(() => {
        if(formReset) reset()
    },[formReset])
    
    //価格select
    const priceValue: SelectValues = [
        {name: '-', value:''},
        {name: '3万円', value:'30000'},
        {name: '4万円', value:'40000'},
        {name: '5万円', value:'50000'},
        {name: '6万円', value:'60000'},
        {name: '7万円', value:'70000'},
        {name: '8万円', value:'80000'},
        {name: '9万円', value:'90000'},
        {name: '10万円', value:'100000'},
        {name: '20万円', value:'200000'},
    ]
    
    const navigate = useNavigate();

    const onSubmit = (currentInput:SearchFromInterface) => {
        window.scrollTo(0,0)
        reset()
        const queryParams = new URLSearchParams()
        // upper_priceとunder_priceを追加
        if(currentInput.upper_price) queryParams.set('upper_price', currentInput.upper_price);
        if(currentInput.under_price) queryParams.set('under_price', currentInput.under_price);

        // 配列型のプロパティについては、forEachを使用して値を一つずつ追加
        if(currentInput.layouts)  queryParams.append('layouts', currentInput.layouts.join(','));
        if(currentInput.cities)  queryParams.append('cities', currentInput.cities.join(','));
        if(currentInput.areas)  queryParams.append('areas', currentInput.areas.join(','));
        if(currentInput.schools)  queryParams.append('schools', currentInput.schools.join(','));
        if(currentInput.categories)  queryParams.append('cate', currentInput.categories.join(','));
        navigate(`${location.pathname}?${queryParams.toString()}`);
        handleUpdate()
    }

    return (
        <SearchFormTemplate>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ChintaiCategorySearchForm register={register} watch={watch}></ChintaiCategorySearchForm>
                <dl className="formGroup">
                    <dd>家賃</dd>
                    <dt className="inputPrice">
                        <div>
                            <SelectForm
                                values={priceValue}
                                register={register}
                                registerName="under_price"
                                watch={watch}
                            ></SelectForm>
                            <span>万円</span>以上
                        </div>
                        <div>
                            <SelectForm
                                values={priceValue}
                                register={register}
                                registerName="upper_price"
                                watch={watch}
                            ></SelectForm>
                            <span>万円</span>
                            以下
                        </div>
                    </dt>
                </dl>
                <dl className="formGroup">
                    <dd>間取り</dd>
                    <dt className="checkboxs">
                         { layouts && 
                            layouts.map((layout,i) => (
                                <Checkbox
                                    register={register}
                                    registerName={'layouts'}
                                    value={layout.name}
                                    id={layout.id}
                                    key={layout.id}
                                    name={layout.name}
                                ></Checkbox>
                            ))
                         }
                    </dt> 
                </dl>
                <SearchFromArea register={register} watch={watch}></SearchFromArea>
                <SubmitFrom value="検索"></SubmitFrom>
            </form>
        </SearchFormTemplate>
    )
}