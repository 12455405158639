const colors = {
    gray:'#FAFAFA',
    grayB:'#E0E0E0',
    grayC:' #F5F5F5',
    black:'#121111',
    blue:' #0033A0',
    chintai:'#FFA726',
    land:'#50a354',
    baibai:'#0033A0',
    red:' #D32F2F'
}   

export default colors 