import { UseFormRegister, Path, RegisterOptions } from "react-hook-form"
import styled from "styled-components"


export const InputForm = <T,>({ register, registerName, type, options, ...props }:
    {   register  : UseFormRegister<T>,
        registerName: Path<T>,
        type:string,
        options? : RegisterOptions
    }) => {
    
    return (
        <SInput  
            {...register(registerName, options)}
            type={type}
        ></SInput>
    )
}

const SInput = styled.input`
    padding:8px;
    font-size: ${props=> props.theme.fontSize.input};
    border: ${props=> props.theme.colors.black} solid 1px;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

`