import styled from "styled-components"


const HanburgarNav = ({active, onClick}:
    {
        active:boolean,
        onClick : () => void
    }) => {

    return (
        <SHanburgar className={active ? 'active' : ''} onClick={onClick}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <span>メニュー</span>
        </SHanburgar>
    )
}

const SHanburgar = styled.nav`
    display: none; /* 最初は非表示にする */
    cursor: pointer;
    .bar{
        width: 30px;
        height: 3px;
        background-color: ${props => props.theme.colors.black};
        margin: 6px 0;
        transition: 0.4s;
    }
    &.active{
        .bar{
            &:nth-child(1){ transform: rotate(-45deg) translate(-5px, 6px); }
            &:nth-child(2){ opacity: 0; }
            &:nth-child(3){ transform: rotate(45deg) translate(-6px, -8px); }
        }   
    }
    span{font-size:1rem; text-align:center; display:block; font-weight:bold; }
    @media (max-width: ${props => props.theme.spWidth }) {
        display: block;
        padding:4px 8px;
        background: #fff;
        border: solid $color-black 1px;
        border-radius: 5px;
    }
`

export default HanburgarNav