import FooterNav from "src/Components/Molecules/Nav/footerNav"
import styled from "styled-components"



const Footer = () => {

    return (
        <SFooter>
            <p className="copyright">Copyrights 有限会社 丸一不動産 / All right reserved.</p>
        </SFooter>
    )
}

const SFooter = styled.footer`
    background: ${props => props.theme.colors.gray};
    padding: 20px 0;
    .copyright{
        padding: 20px;
        font-size: 1.2rem;
        text-align: center;
    }

`

export default Footer