import axios from 'axios'
import { NewBukkenRespons } from 'src/Types/bukken.d'

/**
 * Cities API
 * @returns 
 */
export const fetchNewBuuken = async ():Promise<NewBukkenRespons> => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/index/news.json`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
}