
export const formattedYearMonth = (dateString) => {
    
    const date = new Date(dateString);

    return `${date.getFullYear()}年${date.getMonth() + 1}月`

}

export const formattedYearMonthDay = (dateString) => {
    
    const date = new Date(dateString);

    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`

}