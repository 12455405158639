import axios from 'axios'
import { LandInfoRespons, LandRespons } from 'src/Types/bukken.d';

export const fetchLandIndex = async (query = ''):Promise<LandRespons> => {
    
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/lands/search.json${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
};

export const fetchLandInfo =  async (cId:string):Promise<LandInfoRespons> => {

  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/lands/preview.json?id=${cId}`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }

}

export const fetchLandRecoms = async () => {
    
  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/lands/recoms.json`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
};