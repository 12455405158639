import { useState } from "react"
import Logo from "src/Components/Atoms/Logo"
import HanburgarNav from "src/Components/Molecules/Nav/hanburgarNav"
import HeaderNav from "src/Components/Molecules/Nav/headerNav"
import styled from "styled-components"

const Header = () => {

    const [ navActive, setNavActive] = useState<boolean>(false)
    const handleNav = () => {
        setNavActive(!navActive)
    }

    return (
        <SHeader>
            <div className="header--inner">
                <SHeaderInfo>
                    <div className="logo"><Logo></Logo></div>
                    <div className="info">
                        <div><p className="tel"><a href="tel:0982-53-4489">0982-53-4489</a></p></div>
                        <div className="time">
                            <p> <strong>営業時間</strong>８:３０ – １７:１５</p>
                            <p><strong>定休日</strong>土・日・祝日 </p>
                            <p><span className="notice">定休日・営業時間外もご予約が可能です</span></p>
                        </div>
                    </div>
                </SHeaderInfo>
                <SHamburgerPosition>
                    <HanburgarNav 
                        active={navActive}
                        onClick={handleNav}
                    />
                </SHamburgerPosition>
                <HeaderNav active={navActive}></HeaderNav>
            </div>
        </SHeader>
    )
}

const SHeader = styled.header`
    background: ${props => props.theme.colors.gray };
    position: relative;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    box-shadow: 0px 10px 15px -9px #dad8d8;
`

const SHeaderInfo = styled.div`
    display: flex;
    padding-bottom: 10px;
    .logo{
        margin-left: 0;
        padding: 10px 0;
    }
    .info{
        margin-right: 0;
        padding: 4px 0;
        div:first-child{
            padding-top: 10px;
            display: flex;
            justify-content: right;
        }
        .tel{
            padding-top: 5px;
            font-size: ${props => props.theme.fontSize.tel };
            font-weight: bold;
            margin-bottom: 10px;
            position: relative;
            padding-left: 35px;
            width: fit-content;
            font-size: 2.0rem;
            &::before{
                content: '';
                width: 28px;
                height: 28px;
                background: url('/images/tel.svg')no-repeat;
                background-size: 100% auto;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-45%);
            }
        }
        .time{
            text-align: right;
            line-height: 2.4rem;
            font-size: ${props => props.theme.fontSize.time };
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            p{margin-bottom: 5px;  font-size: 1.3rem; font-weight:bold;}
            p:first-child{margin-right:10px}
            p:last-child{ flex-basis: 100%;}
            strong{ background: #406DA1;display:inline-block; padding:0 5px; color:#fff; border-radius:5px; font-size:1.2rem;margin-right:4px; }
            .notice {
                display:inline-block;
                font-size: 1.2rem;
                font-weight: bold;
                background: #406DA1;
                border-radius:5px;
                color:#fff;
                 padding:0 5px;
            }
        }
    }
    @media (max-width: ${props  => props.theme.spWidth}) {
        display: block;
        width: 100%;
        .info{
            padding: 0;
            display: flex;
            justify-content: space-between;
            div:first-child{
                display: block;
                padding-top: 0;
                margin: 0;
            }
            .tel{  
                text-align: left;
                font-size:  1.8rem;
            }
            .time{
                display: block;
                p:first-child{margin-right:0px}
                p{text-align:left}
                font-size:${props => props.theme.fontSize.timeSp };
                margin: 0;
                line-height: 1.8rem;
                .notice {
                    font-size: 1.2rem;
                }
            }
        }
    }
`

const SHamburgerPosition =styled.div`
    position: absolute;
    top: 20px;
    right: 10px;
`

export default Header