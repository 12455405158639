import { FieldErrors, UseFormRegister, UseFormWatch, useForm } from "react-hook-form"
import { Button } from "src/Components/Atoms/Button"
import { InputForm } from "src/Components/Atoms/Form/input"
import { SelectForm, SelectValues } from "src/Components/Atoms/Form/select"
import SubmitFrom from "src/Components/Atoms/Form/submit"
import TextAraeForm from "src/Components/Atoms/Form/textarea"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import FormTemplate from "src/Components/Templates/formTemplate"
import { BukkenFormInterface } from "src/Types/form.d"


export const BukkenFormComfirm = ({input, confirmCancel}
        : {input : BukkenFormInterface,
            confirmCancel: () => void
        }) => {



    return (
        <FormTemplate>
            <section className="bukken-form confirm">
                <div className="bukken-form--inner">
                    <ContentsTitle>お問合せフォーム</ContentsTitle>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">お問い合わせ種別</label>
                        </dt>
                        <dd className="select">
                            {input.category}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">お問い合わせ物件</label>
                        </dt>
                        <dd>
                            {input.bukken_name}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">名前</label>
                        </dt>
                        <dd>
                            {input.name1} {input.name2}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">住所</label>
                        </dt>
                        <dd>
                            {input.address}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">電話番号</label>
                        </dt>
                        <dd>
                            {input.tel}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">E-mail</label>
                        </dt>
                        <dd>
                            {input.email}
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label>問合せ内容</label>
                        </dt>
                        <dd>
                            {input.info}
                        </dd>
                    </dl>
                    <dl className="formGroup m-b-50">
                        <dt>
                            <label>個人情報取り扱い事項</label>
                        </dt>
                        <dd>
                        同意します
                        </dd>
                    </dl>
                    <SubmitFrom
                        value="送信"
                    ></SubmitFrom>
                    <div className="m-t-20 al-c">
                        <Button onClick={confirmCancel} color="blue"> ← 戻る</Button>
                    </div>
            </div>
        </section>
        </FormTemplate>
    )
}