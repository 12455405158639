import { PropsWithChildren } from "react"
import styled from "styled-components"


const FormTemplate = (    
    {children} : PropsWithChildren) => {

    return (
        <STemplate>
            { children }
        </STemplate>
    ) 
}
const STemplate = styled.div`
    dl.formGroup{
        display: flex;
        margin-bottom: 10px;
        dd,dt{
            box-sizing: border-box;
            padding: 10px;
            white-space: pre-wrap;
        }
        dt{
            flex-basis: 25%;
            display: flex;
            align-items: center;
            label{
                font-size: ${props => props.theme.fontSize.label};
                font-weight: bold;
                position: relative;
                width: 100%;
                &.required{
                    &::after{
                        content: '必須';
                        position: absolute;
                        width: 30px;
                        padding: 3px 0; 
                        font-size: 1.0rem;
                        text-align: center;
                        background: ${props => props.theme.colors.red};;
                        letter-spacing: 0.1rem;
                        color: #fff;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        dd{
            flex-basis: 65%;
        }
        }

        .inputName{
            display: flex;
            max-width: 400px;
            input{
                margin-right: 10px;
            }
        }
        .inputEmail{
            max-width: 400px;
        }
        .inpuKeyword{
            display: flex;
        }
        .inputBirth, .inputPeriod{
            input{
                max-width: 80px;
            }
        }
        .inputPassword{
            max-width: 400px;
        }
        .inputAcademic{
        display: flex;
        & > div{
            box-sizing: border-box;
            &:nth-child(1){
                flex-basis: 30%;
            }   
            &:nth-child(2){
                flex-basis: 20%;
                padding: 0 20px;
            }   
            &:nth-child(3),
            &:nth-child(4){
                flex-basis: 20%;
                max-width: 90px;
                input{
                    width: 50%;
                    margin-right: 10px;
                }
            }
            
        }
        }
        .inputArea, .inputAge{
            input{ max-width: 200px; margin-right: 20px }
        }
        .privacyCheck{
            text-align: center;
            padding: 20px;
        }

        .formback{
        text-align: center;
        button{
            border: none;
            background: none;
            color: ${props => props.theme.colors.blue};
            cursor: pointer;
            border-bottom: solid 1px #fff;
            font-weight: bold;
            &::before{
                content: '＜ ';
            }
            &:hover{
                border-bottom: solid 1px  ${props => props.theme.colors.blue};
            }
        }
        a{
            color:  ${props => props.theme.colors.blue};
            border-bottom: solid 1px #fff;
            display:inline-block;
            &::before{
                content: '＜ ';
            }
            &:hover{
                border-bottom: solid 1px  ${props => props.theme.colors.blue};
            }
        }
        }
        .complate{
        h3{
            text-align: center;
            font-size:  ${props => props.theme.fontSize.h3};
            margin-bottom: 40px;
        }
        p{
            width: fit-content;
            margin: 20px auto;
            line-height: 2.4rem;
        }
        .topback{
            padding: 40px 0;
            text-align: center;
        }
        }

        p.thenkmsg{
            line-height: 2.4rem;
            background: #fff;
            padding: 20px;
            margin-bottom: 40px;
        }
    @media (max-width: ${props  => props.theme.spWidth}) {
        dl.formGroup{
            display: block;
        }
    }
`

export default FormTemplate