import { UseFormRegister, UseFormWatch } from "react-hook-form";
import styled from "styled-components";


export type SelectValues = {name: string, value:string}[]

export const SelectForm = ({ register, watch, registerName , values, ...props} 
    : { register : UseFormRegister<any>, 
        watch: UseFormWatch<any>, 
        registerName:string, 
        values: SelectValues, 
        className?:string}) => {

    const selectedValue = watch(registerName);

    return (
        <SSelect {...register(registerName)} 
            className={props.className ? props.className : '' }>
            {
                values.map((v,i) => (
                    <option value={v.value} key={`selectValue-${i}`}>{v.name}</option>
                ))
            }
        </SSelect>
    )
}

const SSelect = styled.select`
    padding:8px 15px 8px 8px;
    font-size: ${props => props.theme.fontSize.input};
    border: ${props => props.theme.colors.black} solid 1px;
    width: auto;
    margin-bottom: 5px;
    &.w100{
        width: 100%;
    }
`

