import ContentsTitle from "src/Components/Atoms/Title/contentsTitle";
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav";
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate";
import styled from "styled-components";


export const CompanyPage = () => {
    document.title = '会社概要 | 丸一不動産 宮崎県日向市の総合不動産会社'

    return (
       <MaruichiTemplate>
            <div className="contentsHeader--inner">
                <BreadcrumbNav 
                    items={[
                        { name:`会社概要`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                
            </div>  
                <SComapny className="company--inner">
                <section className="aisastu">
                <ContentsTitle>ご挨拶</ContentsTitle>
                <p>
                ご訪問、誠にありがとうございます。丸一不動産は日向市で創業以来35年以上、「お客様に寄り添った不動産サービスの提供」をミッションとして掲げてまいりました。不動産は、ご家族の心の拠りどころとなる大切な場所であり、大事な財産でもあります。お客様に安心いただける安全なお取引をできるよう「調査能力」と「誠実さ」をこれからも大事にしてまいります。
                </p>
                </section>
                <section className="houshin">
                    <ContentsTitle>企業行動方針</ContentsTitle>
                    <ul>
                        <li>ご家族の世代を超えて、見守り、信頼される不動産パートナーとなる。</li>
                        <li>不動産会社は、調査能力が命。「調査・確認・確認」をモットーとし、安心取引を提供する。</li>
                        <li>それが真実か、誠実か、という姿勢を大事にする。</li>
                        <li>不動産会社の立場から、地域貢献のためにできることを考える。</li>
                    </ul>
                </section>
                <section className="daihyo">
                    <h3>
                        有限会社丸一不動産<br/>
                        代表取締役社長　鈴木宝祥（たかよし）
                    </h3>
                    <figure>
                        <img src="/images/daihyo.jpg"/>
                    </figure>
                </section>
                <section className="okyakusama">
                    <ContentsTitle>お客様第一主義</ContentsTitle>
                    <dl>
                        <dt>1.	安心・安全な取引を心がけます</dt>
                        <dd>
                        大切な不動産であるからこそ、安心・安全な取引に徹底することが私たちの責任であると考えています。<br/>
                        <br/>
                        物件の調査については、デスクトップ調査のみならず、現地調査と役所調査を重視してまいりました。不動産価格の相場は、過去35年以上の取引実績から誠実にアドバイスいたします。ご契約の際には、お客様にご安心いただけるよう、売買契約書を一言一句読んだうえで説明してまいります。<br/>
                        <br/>
                        また万一の場合についても当社は、<a href="https://www.hosyo.or.jp/" target="_blank">公益社団法人全国宅地建物取引業保証協会</a>に所属しており、苦情や損害の弁済をお受けいただくことができます。
                        <br/>
                        </dd>
                    </dl>
                    <dl>
                        <dt>2.	不動産を通じて地域の問題解決に努めてまいりました</dt>
                        <dd>
                        これらは実際に当社で問題解決をお手伝いさせていただいたご相談です。<br/>
                        <ul>
                            <li>相続によって権利関係が複雑化した空き家</li>
                            <li>災害指定区域等にある建物</li>
                            <li>目に見えない道路や地役権などによって売却が制限されたお家</li>
                            <li>抵当権・仮登記の設定された空き家</li>
                            <li>隣家の越境が放置されたお家</li>
                            <li>上下水道が未整備の土地</li>
                            <li>隣地との境界があいまいな土地</li>
                            <li>所有者とは異なる占有者が建てた家がある山林</li>
                            <li>債務超過となった不動産のご売却</li>
                        </ul>
                        </dd>
                    </dl>
                    <dl>
                        <dt>3.	不動産業者ネットワークを活用して売却活動を行います</dt>
                        <dd>
                        全国宅地建物取引業連合に所属し、日本最大である不動産流通機構の不動産業者間ネットワークを活用して広報活動に努めてまいります。
                        </dd>
                    </dl>
                    <dl>
                        <dt>4.	相続準備のためのご相談も賜ります</dt>
                        <dd>
                        ご相続されるお家は、ご両親にとっての「子供たちのために残した大切な財産」であり、ご家族にとっては「思い出がたくさん詰まったアルバム」です。相場価値や売却や賃貸までの道のりなど、無料相談を承っております。
                        </dd>
                    </dl>
                    <dl>
                        <dt>5.	不動産の買い取りもいたします</dt>
                        <dd>
                        問題解決に時間を要する場合、誰にも知られずにご売却を進められたい場合など、当社でお引き受けさせていただくことが可能です。
                        </dd>
                    </dl>
                    <dl>
                        <dt>6.	ご面談の予約と秘密厳守</dt>
                        <dd>
                        定休日・祝日・年末年始であってもご予約を承ることが可能です。ご相談いただく内容は秘密厳守いたします。個室でのご面談や、お客様のご自宅にお伺いさせていただくことも可能です。<br/>
                        <br/>
                        様々な問題について、これまでの経験と知識を活かし、関連する専門家である弁護士、税理士、土地家屋調査士、司法書士などの方々と連携を取り合いながら問題解決に努めてまいります。無料相談賜りますのでお気軽にお問い合わせください。
                        </dd>
                    </dl>
                </section> 
                <section className="profile">
                <ContentsTitle>会社概要</ContentsTitle>
                <dl>
                    <dt>会社名</dt>
                    <dd>有限会社 丸一不動産</dd>
                    <dt>代表者</dt>
                    <dd>代表取締役　鈴木 宝祥</dd>
                    <dt>資本金</dt>
                    <dd>
                        3,000,000円
                    </dd>
                    <dt>住所</dt>
                    <dd>
                        〒883-0014 宮崎県日向市原町2丁目112-1
                    </dd>
                    <dt>電話番号</dt>
                    <dd>
                        0982-53-4489
                    </dd>
                    <dt>Fax</dt>
                    <dd>
                        0982-53-4908
                    </dd>
                    <dt>営業時間</dt>
                    <dd>
                    月〜金 8:30 – 17:15<br/>
                    土日祝日・休み
                    </dd>
                    <dt>定休日</dt>
                    <dd>
                    土曜日・日曜日・祝日
                    </dd>
                    <dt>免許番号</dt>
                    <dd>
                    宅地建物取引業　宮崎県知事許可（5） 004336号
                    </dd>
                    <dt>取引金融機関</dt>
                    <dd>
                    宮崎銀行日向支店<br/>
                    鹿児島銀行日向支店<br/>
                    宮崎太陽銀行日向支店
                    </dd>
                    <dt>所属団体</dt>
                    <dd>
                    全国宅地建物取引業協会<br/>
                    全国宅地建物取引業保証協会<br/>
                    賃貸不動産管理業協会
                    </dd>
                    <dt>有資格者</dt>
                    <dd>
                    宅地建物取引士　2名<br/>
                    賃貸不動産管理士　1名<br/>
                    賃貸不動産経営管理士　1名<br/>
                    小額短期保険募集人資格　2名
                    </dd>
                </dl>
                <div className="map_photo">
                    <div className="photo">
                        <img src="./images/batch_1.jpg" alt="丸一不動産"/>
                    </div>
                    <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13471.614507702217!2d131.6311858!3d32.4217395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354715a587680287%3A0x651da2de8b788cbb!2z5pyJ6ZmQ5Lya56S-IOS4uOS4gOS4jeWLleeUow!5e0!3m2!1sja!2sjp!4v1710909686261!5m2!1sja!2sjp" width="600" height="450" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                </section>
            </SComapny>
       </MaruichiTemplate>

    )
}

const SComapny = styled.div`
    margin-bottom: 120px;
    font-size: 1.6rem;
    line-height: 2.6rem;
    a{ color :#0b62e5; text-decoration:underline}
    section{ margin-bottom: 40px }
    ul{
        margin-top: 5px;
        li{
            padding-left: 20px;
            position: relative;
            margin-bottom: 5px;
            &::before{
                content: '●';
                position: absolute;
                left: 0;
            }
        }
    }
    .houshin{
        border: solid 1px #000;
        padding: 20px;
    }
    .daihyo{
        display: flex;

       h3{
            font-size: 1.6rem;
            font-weight: normal;
            flex-basis: 40%;
       }
       @media screen and (max-width: 767px) {
            display: block;
            h3{ margin-bottom : 10px}
            figure{
                text-align: right;
            }
       }
    }
    .okyakusama{
        dl{
            margin-bottom: 20px;
            dt{
                font-weight: bold;
                margin-bottom: 10px;
            }
        }
    }
    .profile{
        dl{ 
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            dt,dd{
                box-sizing: border-box;
                padding: 10px;
                line-height: 3.0rem;
            }
            dt{
                flex-basis: 20%;
                font-weight: bold;
            }
            dd{
                flex-basis: 80%;
            }
        }
        .map_photo{
            display: flex;
            > div{
                flex-basis: 48%;
            }
            .map{
                position: relative;
                width: 100%;
                padding-bottom: 20%;
                iframe{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }   
    
`

