import { ButtonLink } from "src/Components/Atoms/Button"
import { IDoc } from "src/Types/bukken.d"
import styled from "styled-components"


export const Docs = ({ docs } : {docs?:IDoc[]}) => {
    return (
        <SDiv>
        {
            docs && docs.length > 0 ? 
            <ButtonLink 
                color="blue"
                target="_blank" 
                href={`${process.env.REACT_APP_SYSDIR}/${docs[0].dir}/${docs[0].name}`}>
                物件概要（PDF）
            </ButtonLink>
            :  
            <></>
        }
        </SDiv>
    )
}

const SDiv = styled.div`
    margin-bottom: 30px;
    
`