import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "src/Components/Atoms/Button"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import LandBukkens from "src/Components/Organisms/Bukkens/landBukkens"
import { SearchFormKeyword } from "src/Components/Organisms/SearchForm/keyword"
import { SearchFormLand } from "src/Components/Organisms/SearchForm/land"
import { SearchParamDisplay } from "src/Components/Organisms/SearchForm/searchParamDisplay"
import SortNav from "src/Components/Organisms/SortNav"
import { BukkenTemplate } from "src/Components/Templates/bukkenTemplate"
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate"

export const LandsPage = () => {
    document.title = '土地情報 | 丸一不動産 宮崎県日向市の総合不動産会社'

    const [ update, setUpdate ] = useState<number>(0)
    const [ searchReset , setSearchReset] = useState<boolean>(false)
    const [ saerchNavOpen, setSaerchNavOpen] = useState<boolean>(false)

    const navivate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleUpdate = () => {
        setUpdate(update+1)
    }

    const resetLoad = () => {
        navivate(`/lands`);
        setSearchReset(true)
        setUpdate(update+1)
    }

    return (
        <MaruichiTemplate>
            <BukkenTemplate>
            <div className="contentsHeader--inner">
                <BreadcrumbNav 
                    items={[
                        { name:`土地物件一覧`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                <ContentsTitle>土地物件一覧</ContentsTitle>
            </div>
            <div className="bukken-contents--inner">
                <main>
                    <SortNav handleUpdate={handleUpdate} type="land"></SortNav>
                    <div className="searchbtn-wrap">
                        <Button onClick={() => { setSaerchNavOpen(!saerchNavOpen) }} color="red">検索条件設定</Button>
                    </div>
                    <SearchParamDisplay queryParams={queryParams} reset={resetLoad} type="land"/>
                    <SearchFormKeyword queryParams={queryParams} formReset={searchReset}></SearchFormKeyword>
                    <LandBukkens update={update}></LandBukkens>
                </main>
                <aside className={ saerchNavOpen ? 'active' : '' }>
                    <div className="searchbtn-wrap">
                        <Button onClick={() => { setSaerchNavOpen(false) }} color="blue">× 閉じる</Button>
                    </div>
                    <SearchFormLand 
                        handleUpdate={handleUpdate}
                        queryParams={queryParams}
                        formReset={searchReset}
                        ></SearchFormLand>
                </aside>
            </div>
            </BukkenTemplate>
        </MaruichiTemplate>
    )
}