import React, {memo, useState} from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import styled from "styled-components";
import { Image } from "src/Types/bukken.d";
import Modal from 'react-modal';

const ThumbnailItem = memo(({ thumbnail }: { thumbnail:string }) => {
    return (
      <div style={{ margin: "0 5px" }}>
        <img src={thumbnail} alt="" />
      </div>
    )
})

// カスタムPrevボタンのコンポーネント
const ModalImg = ({imgSrc}) => {
  // ウィンドウの高さを取得
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return(
      <img src={imgSrc} style={{maxHeight:`${windowHeight*0.8}px`, maxWidth:`100%`}} />
  )
}

export const SlideGallary = memo(({images}: { images: Image[] }) => {
    
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('')

  const gallary = images.map((img, i) => {
                      return {
                          original:`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`,
                          thumbnail:`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`
                      }
                  })


  const openModal = (e) => {
      console.log(e)
      setImgSrc(e.target.src)
      setIsOpen(true);
  }

  const closeModal = () => {
      setIsOpen(false);
  }   

  const customStyles = {
    overlay: {
        zIndex: 1000
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:'90%',
        maxHeight:'90%',
        textAline: 'center',
        zIndex: 9999  // z-indexをここで設定
      },
  };


  return (
    <>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          >
          <ModalImg {...{imgSrc}}></ModalImg>
      </Modal>
      <SImageGallery>
            <ImageGallery
              items={gallary}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails
              thumbnailPosition="bottom"
              renderThumbInner={(item) => <ThumbnailItem thumbnail={item.thumbnail} />}
              onClick={openModal}
          ></ImageGallery>
      </SImageGallery>
      </>
  )

})

const SImageGallery = styled.div`
  margin-bottom: 30px;
  .image-gallery-image {
    cursor: pointer;
  }
    .image-gallery-slide img {
    /* スライドの画像のスタイルを上書きする */
    height: 400px;
  }

  .image-gallery-thumbnail {
    /* サムネイル全体のスタイルを上書きする */
    width: 12%;
    padding-bottom:10%;
    object-fit: cover;
    cursor: pointer;
    position: relative;
  }

  .image-gallery-thumbnail img {
    /* サムネイル画像のスタイルを上書きする */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: 100%;
  }
`