
import { useState } from "react";
import { useForm } from "react-hook-form";
import { KaitoriKodateForm } from "../KaitoriForm/kodate";
import { fetchContactKaitori } from "src/Data/fetchContact";
import { FormStateType, KaitoriKodateInterFace } from "src/Types/form.d";
import { KaitoriKodateFormSend } from "../KaitoriForm/kodate/send";
import { KaitoriKodateFormConfirm } from "../KaitoriForm/kodate/confirm";
export const KaitoriKodate = () => {
    
    const { register, formState:{errors}, watch, handleSubmit } = useForm<KaitoriKodateInterFace>({
        defaultValues : {
            category : '戸建',
            houseAreaUnit : '坪',
            houseLandAreaUnit : '坪'
        }
    })
    const [ input, setInput ] = useState<KaitoriKodateInterFace>()
    const [ formState, setFormState ] = useState<FormStateType>('input')
    
    const onFormSubmit = (currentInput) => {
        window.scroll(0,0)
        switch(formState){
            case 'input':
                setFormState('confirm')
                setInput(currentInput)
                break;
            case 'confirm' : 
                fetchContactKaitori(currentInput).then((res) => {
                    setFormState('send')
                })
                break;
        }
    }

    const confirmCancel = () => {
        setFormState('input')
    }


    return (
        <>
        { formState === 'input' &&  
            <form onSubmit={handleSubmit(onFormSubmit)}>
            <KaitoriKodateForm
                register={register}
                errors = {errors}
                watch = {watch}
            />
            </form>
        } 
        {
            formState === 'confirm' &&
            <form onSubmit={handleSubmit(onFormSubmit)} className="confirm">
                <KaitoriKodateFormConfirm input={input} confirmCancel={confirmCancel}/>
            </form>
        }
        {
            formState === 'send' &&
            <KaitoriKodateFormSend></KaitoriKodateFormSend>
         }
        </>
    )  
}