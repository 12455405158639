import { useEffect, useState } from "react"
import { BukkenCardBaibai, BukkenCardChintai, BukkenCardLand } from "src/Components/Molecules/Card/bukkenCard"
import { fetchNewBuuken } from "src/Data/fetchCommon"
import { TNewBukken } from "src/Types/bukken.d"
import { formattedYearMonthDay } from "src/utils/dateTime"
import styled from "styled-components"


export const NewBukken = () => {

    const [ newbukkens, setNewBukkens ] = useState<TNewBukken[]>()

    useEffect(() => {
        fetchNewBuuken().then((res) => {
            if(res.respons === 'success') setNewBukkens(res.data)
        } )
    },[])

    return (
        <SNewsList> 
            {
                newbukkens &&
                newbukkens.map((bukken) => (
                    <li key={bukken.bukken.id}>
                        <span className="date">{formattedYearMonthDay(bukken.bukken.created)}</span>
                        {
                            bukken.type === 'chintai' &&
                            <BukkenCardChintai chintai={bukken.bukken} className="nomadori"></BukkenCardChintai> 
                        }
                        {
                            bukken.type === 'land' &&
                            <BukkenCardLand land={bukken.bukken} className="nomadori"></BukkenCardLand> 
                        }
                        {
                            bukken.type === 'baibai' &&
                            <BukkenCardBaibai baibai={bukken.bukken} className="nomadori"></BukkenCardBaibai>
                        }
                    </li>
                ))
            }

        </SNewsList>
    )
}

const SNewsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
        flex-basis: 32.5%;
        margin-bottom: 1%;
        .date{
            background: #eeeeee;
            display: block;
            padding: 5px;
            font-size: 1.2rem;
        }
    }
    @media (max-width: ${props  => props.theme.spWidth}) {
        display: block;
    }
`