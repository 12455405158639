import styled from "styled-components"
import parse from 'html-react-parser';


export const Gmap = ({iframe}:{iframe:string}) => {
    
    return (
        <SGmap>
            {parse(iframe)}
        </SGmap>
    )
}

const SGmap = styled.div`
    position: relative;
    aspect-ratio: 10 / 4;
    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        border:0;
    }
    @media (max-width: ${props  => props.theme.spWidth}) {
        aspect-ratio: 10 / 7;   
    }
`