import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import FormTemplate from "src/Components/Templates/formTemplate";
import { KaitoriLandInterFace } from "src/Types/form.d";
import { InputForm } from "src/Components/Atoms/Form/input"
import { RadioboxForm } from "src/Components/Atoms/Form/radiobox";
import TextAraeForm from "src/Components/Atoms/Form/textarea";
import SubmitFrom from "src/Components/Atoms/Form/submit";

export const KaitoriLandForm = ({register ,errors, watch} :
    { 
        register: UseFormRegister<KaitoriLandInterFace>,
        errors : FieldErrors<KaitoriLandInterFace>
        watch : UseFormWatch<KaitoriLandInterFace>
    }
) => {


    return (
        <FormTemplate>
            <dl className="formGroup">
                <dt><label className="required">物件住所</label></dt>
                <dd>
                    <InputForm register={register} registerName="bukkenAddress" type="text" options={{required:true}}/>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">土地面積 (坪もしくは㎡)</label></dt>
                <dd className="inputArea">
                    <InputForm register={register} registerName="landArea" type="text"  options={{required:true}}/>
                    <RadioboxForm 
                        register={register}
                        id="unit_1"
                        name='landAreaUnit'
                        value="坪"
                    ></RadioboxForm>　
                    <RadioboxForm 
                        register={register}
                        id="unit_2"
                        name='landAreaUnit'
                        value="㎡"
                    ></RadioboxForm>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">接道</label></dt>
                <dd>
                <TextAraeForm 
                        register={register}
                        name="setsudo"
                        options={{required:true}}
                    ></TextAraeForm>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>現況</label></dt>
                <dd>
                  <InputForm register={register} registerName="genkyo" type="text"/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>備考</label></dt>
                <dd>
                    <TextAraeForm 
                        register={register}
                        name="note"
                    ></TextAraeForm>
                </dd>
            </dl>
            <h3>お客様の情報</h3>
            <dl className="formGroup">
                <dt><label className="required">お名前</label></dt>
                <dd>
                <InputForm register={register} registerName="name" type="text" options={{required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">メールアドレス</label></dt>
                <dd>
                <InputForm register={register} registerName="email" type="text" options={{required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">電話番号</label></dt>
                <dd>
                <InputForm register={register} registerName="tel" type="text" options={{required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">住所</label></dt>
                <dd>
                <InputForm register={register} registerName="address" type="text" options={{required:true}}/> 
                </dd>
            </dl>
            <SubmitFrom value="確認画面へ"/>
        </FormTemplate>
    )

}