import styled from "styled-components"
import { PropsWithChildren } from "react"
import Header from "../Organisms/Header"
import Footer from "../Organisms/Footer"

export const BukkenTemplate = (
    {children} : PropsWithChildren) => {

    return (
        <STemplate>
            { children }
        </STemplate>
    ) 
}

const STemplate = styled.div`
    .bukken-contents--inner{
        padding-bottom: 120px;
        display: flex;
        justify-content: space-between;
        .searchbtn-wrap{
            display: none;
            margin-bottom: 20px;
        }
        main{
            flex-basis: 65%;
        }
        aside{
            flex-basis: 32%;
        }
    }
    .bukken-images{
        display: flex;
        justify-content: space-between;
        & > div{
            flex-basis: 48%;
        }
    }

    .bukken-info{
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        dl{
            flex-basis: 50%;
            box-sizing: border-box;
            display: flex;
           
            border-right : solid 1px ${props => props.theme.colors.black};
            &:nth-child(1),&:nth-child(2){
                border-top : solid 1px ${props => props.theme.colors.black};
            }
            &:nth-child(odd){
                border-left : solid 1px ${props => props.theme.colors.black};
            }
            dd,dt{
                box-sizing: border-box;
                padding: 15px 10px;
            }
            dt{
                flex-basis: 20%;
                border-right: solid 1px ${props => props.theme.colors.black};
                border-bottom: solid 1px ${props => props.theme.colors.black};
                font-size: ${props => props.theme.fontSize.bukkenLabel};
                background: ${props => props.theme.colors.gray};
            }
            dd{
                flex-basis: 80%;     
                border-bottom: solid 1px ${props => props.theme.colors.black};       
            }
        }
    
        
    }

    .bukken-form{
        margin: 40px 0;
        padding: 40px 0;
        background: ${props => props.theme.colors.grayC};
        h2{
            margin-bottom: 40px;
        }
        .bukken-form--inner{
            max-width: 840px;
        }
        &.confirm{
            dd{
                background: #fff;
            }
        }
    }

    p.thenkmsg{
        line-height: 2.4rem;
        background: #fff;
        padding: 20px;
        margin-bottom: 40px;
    }

    .bukkenHeader{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 20px;
        & > div{
            padding: 20px;
            background: ${props => props.theme.colors.grayC};
            margin: 0;
            margin-bottom: 1%;
            margin-right: 10px;
            border-radius: 5px;
            &.layout,
            &.price{
                label{ display: block; 
                    margin-bottom: 10px; 
                    font-size: ${props => props.theme.fontSize.bukkenLabel};
                    font-weight: normal;
                }
                font-size: 2.0rem;
                font-weight: bold;
            }
            &.other{
                span{
                    display: inline-block;
                    margin-bottom: 10px;
                    &::after{
                        content: ' / ';
                    }
                }
            }
            &.memo{
                h4{ margin-bottom: 10px; }
                flex-basis: 100%;
                line-height: 2.4rem;
            }
        }
    }

    .categorytab{
        display: inline-block;
        margin-right: 10px;
        background: ${props => props.theme.colors.blue};
        padding: 5px 5px;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 14px;
    }

    @media (max-width: ${props  => props.theme.spWidth}) {
        .bukken-contents--inner{
            padding-bottom: 120px;
            display: block;
            justify-content: space-between;
            .searchbtn-wrap{
                display: block;
            }
            main{ }
           
            aside{
                padding: 20px;
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: #fff;
                overflow-y:scroll;
                z-index: 999;
                display: none;
                &.active{
                    display: block;
                }
            }
        }
        .bukken-images{
            display: block;
            justify-content: space-between;
            & > div{
                flex-basis: 48%;
            }
        }
        .bukken-info{
            padding: 40px 0;
            display: block;
            dl{
                border-left: solid 1px ${props => props.theme.colors.black};
                &:nth-child(2){
                    border-top : none;
                }
            }
        }
    }

`