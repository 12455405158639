import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


export const ButtonLink = (
    {href, color, target = '_self', children, ...props} : 
    PropsWithChildren<{ href:string, color:string, target?:string}> ) => {
    
    return (
        <SButtonLink to={href} className={color} target={target}>
            {children}
        </SButtonLink>
    )
}

export const ButtonMore = ({onClick, color, children}:
    PropsWithChildren<{ onClick: ()=>void, color:string}>) => {

        return (
            <SButtonMore onClick={onClick} className={color}>
                {children}
            </SButtonMore>
        )

}

export const Button = ({onClick, color, children}:
    PropsWithChildren<{ onClick: ()=>void, color:string}>) => {
    
        return (
            <SButton onClick={onClick} className={color}>
                {children}
            </SButton>
        )
}



const SButton = styled.button`
    border: solid 1px ${props => props.theme.colors.grayB};
    display: inline-block;
    padding:10px 20px;
    background: #fff;
    font-size: ${props => props.theme.fontSize.btn};
    font-weight: bold;
    cursor: pointer;
    &.blue{
        border: solid 1px ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.blue};
        &:hover{
            background:${props => props.theme.colors.blue};
            color: #fff;
        }
    }
    &.red{
        border: solid 1px  ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
        &:hover{
            background:${props => props.theme.colors.red};
            color: #fff;
        }
    }
`

const SButtonMore = styled(SButton)`
     border-radius: 5px;
     background: #fff;
     width: 90%;
     font-size: 1.8rem;
     
`

const SButtonLink = styled(Link)`
    border: solid 1px ${props => props.theme.colors.grayB};
    display: inline-block;
    padding:10px 20px;
    font-size: ${props => props.theme.fontSize.btn};
    font-weight: bold;
    &.blue{
        border: solid 1px ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.blue};
        &:hover{
            background:${props => props.theme.colors.blue};
            color: #fff;
        }
    }
    &.red{
        border: solid 1px  ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
        &:hover{
            background:${props => props.theme.colors.red};
            color: #fff;
        }
    }
`

