import styled from "styled-components"


const SubmitFrom = ({value, ...props}:{value:string, className?:string}) => {

    return (
        <SSubmit className={props.className ? props.className : ''}>
            <input type="submit" value={value}></input>
        </SSubmit>
    )
}

const SSubmit = styled.div`
    width: fit-content;
    margin: 20px auto;
    position: relative;

    input[type=submit]{
        background: #fff;
        border: solid 1px ${props => props.theme.colors.black};
        font-size: ${props => props.theme.fontSize.submit};
        letter-spacing: 0.1rem;
        font-weight: 900;
        color:${props => props.theme.colors.black};
        padding: 10px;
        width: 200px;
        max-width: 100%;
        cursor: pointer;
        position: relative;
        &:hover{
            background: ${props => props.theme.colors.blue};
            color: #fff;
        }
        &.dark{
            background:${props => props.theme.colors.black};
        }
    }
    &.login,&.passwordReset{
        input[type=submit]{
            background: ${props => props.theme.colors.blue};
        }
    }
`

export default SubmitFrom;