import { ButtonLink } from "src/Components/Atoms/Button"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import FormTemplate from "src/Components/Templates/formTemplate"


export const InfoFormSend = () => {




    return (
        <FormTemplate>
            <section className="bukken-form">
                <div className="bukken-form--inner">
                    <ContentsTitle>お問合せフォーム -　送信完了</ContentsTitle>
                    <p className="thenkmsg">
                    お問い合わせ請求ありがとうございます。<br/>
                    <br/>
                    しばらく経ってもメールが届かない場合は、入力頂いたメールアドレスが間違っているか、迷惑メールフォルダに振り分けられている可能性がございます。<br/>
                    また、ドメイン指定をされている場合は、「noreplay@maru-fudousan.jp」からのメールが受信できるようあらかじめ設定をお願いいたします。<br/>
                    以上の内容をご確認の上、お手数ですがもう一度フォームよりお申し込み頂きますようお願い申し上げます。
                    </p>
                    <div className="al-c">
                        <ButtonLink href="/" color="red" >TOPへ戻る</ButtonLink>
                    </div>
            </div>
        </section>
        </FormTemplate>
    )
}