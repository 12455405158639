import styled from "styled-components"
import { UseFormRegister, Path, RegisterOptions } from 'react-hook-form';
import { Children } from "react";
import { InputForm } from "./input";


type InputProps<TFormValues> = {
    register: UseFormRegister<TFormValues>;
    name: Path<TFormValues>;
    id : string,
    value : string,
    options? : RegisterOptions
};

export const RadioboxForm =  <TFormValues,>({ register, name, options, id, value }
    : InputProps<TFormValues>) => {

    return (
        <SRadio>
            <input type="radio" {...register(name, options)} value={value} id={id} />
            <label htmlFor={id}>{value}</label>
        </SRadio>
    )
}



const SRadio = styled.div`
    display: inline-block;
    border: solid 1px ${props => props.theme.colors.black};
    padding: 15px 0px 15px 10px;
    border-radius: 5px;
    background: #fff;
    input[type=radio]{
        /* コンテンツの流れから切り離す */
        position: absolute;
        /* 誤ったコードに対処するための回避策 */
        white-space: nowrap;
        /* 可能な限り文字サイズを小さくするための処理
        * (スクリーンリーダー中には height と width が 0 のものを無視するため)
        */
        width: 1px;
        height: 1px;
        /* オーバーフローしているコンテンツを隠す */
        overflow: hidden;
        /* 要素サイズを変更しうるプロパティのリセット */
        border: 0;
        padding: 0;
        /* 要素のどの部分が表示されるかを定義するもの */
        /* 古いブラウザでは使用できない */
        clip: rect(0 0 0 0);
        /* 最近のブラウザ用
        * コンテンツを非表示にする設定  */
        clip-path: inset(50%); 
        /* 今現在なぜ-1pxがここで設定されるかは分かっていないそうです。
        * それに加えていくつか問題もあるそうです 
        * (参考: https://github.com/h5bp/html5-boilerplate/issues/1985)
        */
        margin: -1px;
    }
    label {
        cursor: pointer;
        padding-left: 30px;
        padding-right: 45px;
        position: relative;
        font-size: ${props=> props.theme.fontSize.input};
      }
      
      label::before,
      label::after {
        content: "";
        display: block;
        border-radius: 50%;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
      }
      
      label::before {
        background-color: #fff;
        border: 1px solid ${props => props.theme.colors.blue};
        height: 20px;
        width: 20px;
        left: 5px;
      }
      
      label::after {
        background-color: ${props => props.theme.colors.blue};
        opacity: 0;
        height: 16px;
        width: 16px;
        left: 8px;
      }
      
      input[type=radio]:checked + label::after {
        opacity: 1;
      }
      
`

