import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import { CategoriesChintai } from "src/Components/Organisms/Categories/chintai"
import { NewBukken } from "src/Components/Organisms/NewBukken"
import News from "src/Components/Organisms/News"
import OsusumeTab from "src/Components/Organisms/OsusumeTab"
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate"
import styled from "styled-components"



export const TopPage = () => {
    document.title = '丸一不動産 宮崎県日向市の総合不動産会社'
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        async function fetchPosts() {
            try {
                const response = await axios.get('https://maru-fudousan.jp/news/wp-json/wp/v2/posts');
                setPosts(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchPosts();
      
    }, []);

    return (
        <MaruichiTemplate>
            <SMain>
                <div className="main--inner">
                    <div className="left">
                        <figure className="box main-img">
                            <video src="./mp4/top.mp4" 
                                poster='./images/company.jpg'
                                playsInline autoPlay muted controls={true} loop={true} className="openning-video"></video>
                        </figure>
                        <div className="box categories">
                            <CategoriesChintai handleUpdate={()=>{}}/>
                        </div>
                        <div className="box news">
                            <ContentsTitle>新着情報</ContentsTitle>
                            <News posts={posts.slice(0,5)}></News>
                        </div>
                    </div>
                    <div className="right">
                        <div className="sp767">
                            <ContentsTitle>サイトメニュー</ContentsTitle>
                            <div className="sp-nav">
                            <Link to="/">TOP</Link>
                            <Link to="/chintais">賃 貸</Link>
                            <Link to="/baibais">中古物件</Link>
                            <Link to="/lands">土 地</Link>
                            <Link to="/kaitori">買取相談</Link>
                            <Link to="/infomation">ご来店予約 / <br/>お問い合わせ</Link>
                            <a href="http://www.hyuga.jp/blog/page/marufudo">ブログ</a>
                            <Link to="/company">ご挨拶</Link>
                            <a href="https://maru-fudosan.com/app/applicant-form/" target="_blank">ウェブ入居申込み</a>
                            <Link to="/download">書式</Link>
                            </div>
                        </div>
                        <section className="box osusume">
                            <ContentsTitle>おすすめ物件</ContentsTitle>
                            <OsusumeTab></OsusumeTab>
                        </section>
                    </div>
                </div>
                <div className="news--inner">
                    <ContentsTitle>新着物件</ContentsTitle>
                    <NewBukken></NewBukken>
                </div>
                
            </SMain>
        </MaruichiTemplate>
    )
}

const SMain = styled.main`
.main--inner{
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding:20px 20px 60px;
        .left{
            flex-basis: 55%;
            margin: 0;
        }
        .right{
            box-sizing: border-box;
            flex-basis: 40%;
             padding-left: 20px;
        }
        .categories{
            margin: 30px 0;
        }
        .sp-nav{
            display: none;
        }
        .main-img{
            position: relative;
            width: 100%;
            aspect-ratio: 10/6;
            video{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
        @media (max-width: ${props  => props.theme.spWidth}) {
            .sp-nav{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 30px;
                & > *{
                    flex-basis: 50%;
                    padding: 5px 10px;
                    border-left:solid 4px ${props => props.theme.colors.blue};
                    border-bottom: solid 1px  ${props => props.theme.colors.grayC};
                    margin-bottom: 8px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }
                a:hover{
                    background: ${props => props.theme.colors.blue};
                    color:#fff;
                }
                
            }
            .main-img{
                position: absolute;
                top: 0;
                left: 0;
                padding: 20px; 
                width: 90%;
                video{
                    width: 90%;
                    left: 50%;
                    transform:translateX(-50%);
                }               
            }
           .left, .right{
                flex-basis: 100%;
            }
            .left{
                order: 2;
            }
            .right{
                &::before{
                    content: '';
                    width: 100%;
                    aspect-ratio: 10/7.5;
                    display:block;
                }
                padding: 0;
                order: 1;
            }
        }
    }
    .news--inner{
        padding-bottom: 120px;
    }
`