import axios from 'axios'
import { BukkenFormInterface } from 'src/Types/form.d';

export const fetchContact = async (contact:FormData) => {
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API}/contact/contact.json`, contact);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
};


export const fetchContactBukken = async (contact:BukkenFormInterface) => {
    
  try {
      const res = await axios.post(`${process.env.REACT_APP_API}/contact/bukken.json`, contact);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
};

export const fetchContactKaitori = async <T,>(contact:T ) => {
    
  try {
      const res = await axios.post(`${process.env.REACT_APP_API}/contact/satei.json`, contact);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
};

