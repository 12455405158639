import { useState } from "react";
import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import { SelectForm, SelectValues } from "src/Components/Atoms/Form/select";
import FormTemplate from "src/Components/Templates/formTemplate";
import { KaitoriKodateInterFace } from "src/Types/form.d";
import { InputForm } from "src/Components/Atoms/Form/input"
import { RadioboxForm } from "src/Components/Atoms/Form/radiobox";
import TextAraeForm from "src/Components/Atoms/Form/textarea";
import SubmitFrom from "src/Components/Atoms/Form/submit";

export const KaitoriKodateForm = ({register ,errors, watch} :
    { 
        register: UseFormRegister<KaitoriKodateInterFace>,
        errors : FieldErrors<KaitoriKodateInterFace>
        watch : UseFormWatch<KaitoriKodateInterFace>
    }
) => {

    const [actvieTab, setActvieTab] =  useState(1) 


    const houseLayouts: SelectValues = [
        {name: '1K・1DLK', value: '1K・1DLK'},
        {name: '2DK・2DLK', value: '2DK・2DLK'},
        {name: '3DK・3DLK', value: '3DK・3DLK'},
        {name: '4DK・4DLK', value: '4DK・4DLK'},
        {name: '5DK・5DLK 以上', value: '5DK・5DLK 以上'},
        {name: 'その他', value: 'その他'},
    ]

    return (
        <FormTemplate>
            <dl className="formGroup">
                <dt><label className="required">物件住所</label></dt>
                <dd>
                    <InputForm register={register} registerName="bukkenAddress" type="text"  options={ {required:true}}/>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">間取り</label></dt>
                <dd>
                    <SelectForm
                        values={houseLayouts}
                        register={register}
                        registerName="houseLayout"
                        watch={watch}
                        className="w100"
                    ></SelectForm>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">建物面積 (坪もしくは㎡)</label></dt>
                <dd className="inputArea">
                    <InputForm register={register} registerName="houseArea" type="text" options={ {required:true}}/>
                    <RadioboxForm 
                        register={register}
                        id="unit_1"
                        name='houseAreaUnit'
                        value="坪"
                    ></RadioboxForm>
                    　
                    <RadioboxForm 
                        register={register}
                        id="unit_2"
                        name='houseAreaUnit'
                        value="㎡"
                    ></RadioboxForm>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">土地面積 (坪もしくは㎡)</label></dt>
                <dd className="inputArea">
                    <InputForm register={register} registerName="houseLandArea" type="text" options={ {required:true}}/>
                    <RadioboxForm 
                        register={register}
                        id="unit_land_1"
                        name='houseLandAreaUnit'
                        value="坪"
                    ></RadioboxForm>
                    　
                    <RadioboxForm 
                        register={register}
                        id="unit_land_2"
                        name='houseLandAreaUnit'
                        value="㎡"
                    ></RadioboxForm>
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">築年数（もしくは築年月）</label></dt>
                <dd className="inputAge">
                <InputForm register={register} registerName="houseAge" type="text" options={ {required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>現況</label></dt>
                <dd>
                  <InputForm register={register} registerName="genkyo" type="text"/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>備考</label></dt>
                <dd>
                    <TextAraeForm 
                        register={register}
                        name="note"
                    ></TextAraeForm>
                </dd>
            </dl>
            <h3>お客様の情報</h3>
            <dl className="formGroup">
                <dt><label className="required">お名前</label></dt>
                <dd>
                <InputForm register={register} registerName="name" type="text"  options={ {required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">メールアドレス</label></dt>
                <dd>
                <InputForm register={register} registerName="email" type="text"  options={ {required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">電話番号</label></dt>
                <dd>
                <InputForm register={register} registerName="tel" type="text"  options={ {required:true}}/> 
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label className="required">住所</label></dt>
                <dd>
                <InputForm register={register} registerName="address" type="text"  options={ {required:true}}/> 
                </dd>
            </dl>
            <SubmitFrom value="確認画面へ"/>
        </FormTemplate>
    )

}