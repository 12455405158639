import { ButtonLink } from "src/Components/Atoms/Button"
import { BaibaiIndex, ChintaiIndex, LandIndex } from "src/Types/bukken.d"
import styled from "styled-components"
import { formatCurrency, numFormat } from "src/utils/textUtils"
import { formattedYearMonth }  from "src/utils/dateTime"


/**========== 
 * 賃貸物件カード
 -------------------* */ 
export const BukkenCardChintai = ({chintai, ...props}:{
    chintai:ChintaiIndex,
    className? :string
}) => {
    let icatchSrc;
    chintai.images[0] ? icatchSrc = `${process.env.REACT_APP_SYSDIR}/${chintai.images[0].dir}/${chintai.images[0].name}`
                         : icatchSrc = `${process.env.REACT_APP_DIR}images/noimg.png`
    let madoriSrc;
    chintai.madoris[0] ? madoriSrc = `${process.env.REACT_APP_SYSDIR}/${chintai.madoris[0].dir}/${chintai.madoris[0].name}`
                         : madoriSrc = null
    
    const other = chintai.other ? JSON.parse(chintai.other) : undefined            
    return (
        <SCard className={`chintai ${props.className}`}>
            <h3 className="cardTitle">{ other.pr_title ?  other.pr_title : chintai.name}</h3>
            <div className="upper-row">
                <figure><img src={icatchSrc} /></figure>
                <figure className="madori-img">
                    { madoriSrc && 
                    <img src={madoriSrc} />
                    }
                </figure>
                <div className="info-emp">
                    <dl>
                        <dd>間取り</dd>
                        <dt>{chintai.search_params.layout}</dt>
                        <dd>家賃</dd>
                        <dt>{numFormat(chintai.price)}円</dt>
                        <dd>敷金</dd>
                        <dt>{chintai.security_deposit ? formatCurrency(chintai.security_deposit) : '-'}</dt>
                        <dd>礼金</dd>
                        <dt>{chintai.key_money ? formatCurrency(chintai.key_money) : '-'}</dt>
                    </dl>
                </div>
            </div>
            <div className="lower-row">
                <div className="info">
                    <p>
                        <span>{chintai.search_params.layout}</span>
                        {chintai.built_year_month &&
                         <span>築年月 { formattedYearMonth(chintai.built_year_month)}</span>
                        }
                    </p>
                    <p>
                        <span>{chintai.address} </span><span>{chintai.search_params.jhs}</span>
                    </p>
                </div>
                <div className="viewMore">
                    <ButtonLink 
                        href={`/chintais/${chintai.id}?window=on`}
                        color="blue"
                        target="_blank"
                        >詳細を見る</ButtonLink>
                </div>
            </div>
        </SCard>
    )
}

/**========== 
 * 中古住宅物件カード
 -------------------* */ 
export const BukkenCardBaibai = ({baibai, ...props}:{
    baibai:BaibaiIndex
    className? :string
}) => {
    let icatchSrc;
    baibai.images[0] ? icatchSrc = `${process.env.REACT_APP_SYSDIR}/${baibai.images[0].dir}/${baibai.images[0].name}`
                         : icatchSrc = `${process.env.REACT_APP_DIR}images/noimg.png`
    let madoriSrc;
    /*
    baibai.madoris[0] ? madoriSrc = `${process.env.REACT_APP_SYSDIR}/${baibai.madoris[0].dir}/${baibai.madoris[0].name}`
                         : madoriSrc = null
    */
    return (
        <SCard className={`baibai ${props.className}`}>
            <h3 className="cardTitle">{baibai.name}</h3>
            <div className="upper-row">
                <figure><img src={icatchSrc} /></figure>
                <figure className="madori-img">
                    { madoriSrc && 
                    <img src={madoriSrc} />
                    }
                </figure>
                <div className="info-emp">
                    <dl>
                        <dd>価格</dd>
                        <dt>{baibai.price}</dt>
                        <dd>間取り</dd>
                        <dt>{baibai.room_layout ? baibai.room_layout : '-'}</dt>
                        <dd>住所</dd>
                        <dt>{baibai.address ? baibai.address : '-'}</dt>
                    </dl>
                </div>
            </div>
            <div className="lower-row">
                <div className="info">
                    <p>
                        <span>建物面積 : {baibai.building_area} </span><span>土地面積 : {baibai.land_area}</span>
                    </p>
                </div>
                <div className="viewMore">
                    <ButtonLink 
                        href={`/baibais/${baibai.id}?window=on`}
                        target="_blank"
                        color="blue">詳細を見る</ButtonLink>
                </div>
            </div>
        </SCard>
    )     
}

/**============= 
 * 土地物件カード
 -------------------* */ 
 export const BukkenCardLand = ({land, ...props}:{
    land:LandIndex
    className? :string
}) => {
    let icatchSrc;
    land.images[0] ? icatchSrc = `${process.env.REACT_APP_SYSDIR}/${land.images[0].dir}/${land.images[0].name}`
                         : icatchSrc = `${process.env.REACT_APP_DIR}images/noimg.png`
   
    
    return (
        <SCard className={`land ${props.className}`}>
            <h3 className="cardTitle">{land.name}</h3>
            <div className="upper-row">
                <figure><img src={icatchSrc} /></figure>
                <div className="info-emp">
                    <dl>
                        <dd>価格</dd>
                        <dt>{land.price}</dt>
                        <dd>住所</dd>
                        <dt>{land.address}</dt>
                        <dd>土地面積 / 坪数</dd>
                        <dt>{land.land_area ? land.land_area : '-'} / {land.tubo ? land.tubo : '-'}</dt>
                    </dl>
                </div>
            </div>
            <div className="lower-row">
                <div className="info">
                    <p>
                        <span>{land.search_params.jhs}</span>
                    </p>
                </div>
                <div className="viewMore">
                    <ButtonLink 
                        href={`/lands/${land.id}?window=on`}
                        target="_blank"
                        color="blue">詳細を見る</ButtonLink>
                </div>
            </div>
        </SCard>
    )     
}

const SCard = styled.div`
    border: solid 1px ${props => props.theme.colors.gray};
    padding: 10px 0;
    .cardTitle{
        font-size: ${props => props.theme.fontSize.cardTitle};
        padding: 10px;
        margin-bottom: 10px;
    }
    &.chintai{
        .cardTitle{
            background: ${props => props.theme.colors.chintai};
            color: #fff;
        }
    }
    &.baibai{
        .cardTitle{
            background: ${props => props.theme.colors.baibai};
            color: #fff;
        }
    }
    &.land{
        .cardTitle{
            background: ${props => props.theme.colors.land};
            color: #fff;
        }
    }
    &.nomadori{
        .madori-img{
            display: none;
        }
        .upper-row{
            figure{
                flex-basis: 40%;
            }
        }
    }

    .upper-row{
        display: flex;
        margin-bottom: 10px;
        figure{
            flex-basis: 30%;
            position: relative;
            aspect-ratio: 10/7;
            img{
                position: absolute;
                max-height: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .info-emp{
            display: flex;
            align-items: center;
            margin: 0;
            margin-left: 10px;
            dl{
                display: flex;
                flex-wrap: wrap;
                dd,dt{
                    box-sizing: border-box;
                    display: flex;
                    align-items: last baseline;
                    margin-bottom: 10px;
                }
                dd{
                    flex-basis: 25%;
                    font-size: 1.2rem;
                    font-weight: bold;
                    
                } 
                dt{
                    flex-basis: 75%;
                    padding-left: 5px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: ${props => props.theme.colors.red};
                }
            }
        }
    }

    .lower-row{
        display: flex;
        .info{
            margin-left: 0;
           font-size: ${props => props.theme.fontSize.info};
           line-height: 2.0rem;
           flex-basis: 60%;
           span{
                display: inline-block;
                margin-right: 5px;
                &::before{
                    content: '・';
                }
           }
        }
        .viewMore{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-basis: 40%;
            .btn{
                width: 200px;
                text-align: center;
                max-width: 100%;
            }
        }
    }
    
`

