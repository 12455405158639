import { UseFormRegister, Path, RegisterOptions } from "react-hook-form"
import styled from "styled-components"


const TextAraeForm = <T,>({register, name, options}
    : { register:UseFormRegister<T>, 
        name: Path<T>,
        options? : RegisterOptions
    }) => {
    return (
        <STextArea
            {...register(name, options)}
        ></STextArea>
    )
}

const STextArea = styled.textarea`
    width: 100%;
    padding:8px;
    font-size: ${props => props.theme.fontSize.input};
    border: ${props => props.theme.colors.black} solid 1px;
    border-radius: 5px;
    min-height: 200px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
`

export default TextAraeForm