import { BaibaiInfo, ChintaiInfo, LandInfo } from "src/Types/bukken.d";
import { formattedYearMonth } from "src/utils/dateTime";
import { textToBr } from "src/utils/textUtils";
import parse from 'html-react-parser';

interface Colum {
    key: string,
    name : string
}



const ColumTable = <T,>({colums, detail}: {
    colums : Colum[],
    detail : T 
} ) => {

    return (
       <>
             { 
                colums.map((c, i) => {
                    if(detail[c.key] && (detail[c.key] != 'undefined' && detail[c.key] != 'null')){
                        return (
                            <dl key={`ColumTable-${i}`}>
                                <dt>{c.name}</dt>
                                <dd>
                                    {
                                        c.key === 'built_year_month' ? formattedYearMonth(detail[c.key]) : 
                                                    (typeof detail[c.key] === 'string' ? parse(textToBr(detail[c.key])) : detail[c.key])
                                        
                                    }
                                </dd>
                            </dl>
                        )
                    }else{}
                })
            }
        </>
    )       
}

export const ChintaiColumTable = ({detail}:{detail:ChintaiInfo}) => {

    const colums = [
        {key: 'management_expenses', name: '管理費'},
        {key: 'common_service_fee', name: '共益費'},
        {key: 'security_deposit', name: '敷金'},
        {key: 'key_money', name: '礼金'},
        {key: 'deposit', name: '保証委託料'},
        {key: 'address', name: '所在地'},
        {key: 'room_layout', name: '間取り'},
        {key: 'proprietary_area', name: '専有面積'},
        {key: 'floor', name: '階'},
        {key: 'direction', name: '向き'},
        {key: 'building_type', name: '建物種別'},
        {key: 'room_layout', name: '間取り'},
        {key: 'structure', name: '構造'},
        {key: 'built_year_month', name: '築年月'},
        {key: 'property_insurance', name: '保険'},
        {key: 'parking', name: '駐車場'},
        {key: 'move_in', name: '入居時期'},
        {key: 'trading_aspect', name: '取引態様'},
        {key: 'total_of_houses', name: '総戸数'},
     //   {key: 'summary', name: '部屋の特徴・設備'},
        {key: 'traffic', name: '路線・バス'},
        {key: 'surroundings', name: '周辺環境'},
        {key: 'school', name: '学校区'},
        {key: 'school_traffic', name: '学校までの距離'},
        {key: 'brokerage', name: '仲介料'},
        {key: 'equipment', name: '設備'},
    ]

    return (
        <ColumTable<ChintaiInfo>
            colums={colums}
            detail={detail}
        ></ColumTable>
    )

}

export const BaibaiColumTable = ({detail}:{detail:BaibaiInfo}) => {

    const colums = [
        {key: 'address', name: '住所'},
        {key: 'traffic', name: '路線・バス'},
        {key: 'summary', name: '物件の特徴'},
        {key: 'total_sales', name: '販売戸数'},
        {key: 'total_of_houses', name: '総戸数'},
        {key: 'land_area', name: '土地面積'},
        {key: 'building_area', name: '建物面積'},
        {key: 'private_road', name: '私道負担・道路'},
        {key: 'built_year_month', name: '築年月'},
        {key: 'address', name: '住所'},
        {key: 'floor_area_ratio', name: '建ぺい率・容積率'},
        {key: 'dealing_time', name: '引渡可能時期'},
        {key: 'construction', name: '施工'},
        {key: 'reform', name: 'リフォーム'},
        {key: 'use_district', name: '用途地域'},
        {key: 'land_category', name: '地目'},
        {key: 'city_plan', name: '都市計画'},
        {key: 'current', name: '現況'},
        {key: 'waterway', name: '上下水道'},
        {key: 'structure', name: '構造'},
        {key: 'surroundings', name: '周辺環境'},
        {key: 'school', name: '学校名'},
        {key: 'school_traffic', name: '学校までの距離'},
        {key: 'other_limit_matter', name: 'その他制限事項'},
        {key: 'other_overview_notice', name: 'その他概要・特記事項'},
    ]

    return (
        <ColumTable<BaibaiInfo>
            colums={colums}
            detail={detail}
        ></ColumTable>
    )
}

export const LandColumTable = ({detail}:{detail:LandInfo}) => {

    const colums = [
        {key: 'address', name: '住所'},
        {key: 'land_category', name: '地目'},
        {key: 'use_district', name: '用途地域'},
        {key: 'floor_area_ratio', name: '建ぺい率･容積率'},
        {key: 'land_area', name: '土地面積'},
        {key: 'tubo', name: '坪数'},
        {key: 'total_sales', name: '販売区画数'},
        {key: 'total_area', name: '総区画数'},
        {key: 'private_road', name: '私道負担・道路'},
        {key: 'land_status', name: '土地状況'},
        {key: 'traffic', name: '路線・バス'},
        {key: 'land_rights', name: '土地の権利形態'},
        {key: 'building_criteria', name: '建築条件'},
        {key: 'dealing_time', name: '引き渡し時期'},
        {key: 'earthwork_completion_date', name: '造成完了時期'},
        {key: 'city_plan', name: '都市計画'},
        {key: 'current', name: '現況'},
        {key: 'waterway', name: '上下水道'},
        {key: 'surroundings', name: '周辺環境'},
        {key: 'school', name: '学校名'},
        {key: 'school_traffic', name: '学校までの距離'},
        {key: 'other_limit_matter', name: 'その他制限事項'},
        {key: 'other_overview_notice', name: 'その他概要・特記事項'},
    ]

    return (
        <ColumTable<LandInfo>
            colums={colums}
            detail={detail}
        ></ColumTable>
    )
}