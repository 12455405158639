import { Link } from "react-router-dom"
import theme from "src/Themes"
import styled from "styled-components"


const HeaderNav = ({active}:
    {
        active:boolean,
    }) => {


    return (
        <SHeaderNav className={active ? 'active' : ''}>
            <Link to="/">TOP</Link>
            <Link to="/chintais">賃 貸</Link>
            <Link to="/baibais">中古物件</Link>
            <Link to="/lands">土 地</Link>
            <Link to="/kaitori">買取相談</Link>
            <Link to="/infomation">ご来店予約・お問い合わせ</Link>
            <a href="http://www.hyuga.jp/blog/page/marufudo">ブログ</a>
            <Link to="/company">ご挨拶</Link>
            <a href="https://maru-fudosan.com/app/applicant-form/" target="_blank">ウェブ入居申込み</a>
            <Link to="/download">書式</Link>
        </SHeaderNav>
    )
}

const SHeaderNav = styled.nav`
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
   


    a{
        text-align: center;
        display: block;
        padding: 4px;
        letter-spacing: 0.1rem;
        font-size: ${props => props.theme.fontSize.headerNav};
        position: relative;
        &:hover{
            &::after{
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                left: 0;
                bottom: 0;
                background: ${props => props.theme.colors.black};
            }
        }
    }
    @media (max-width: ${props => props.theme.spWidth }) {
        display: none; /* メニューを非表示にする */
        flex-direction: column;
        a{
            display: block;
            text-align: right;
            margin-bottom: 10px;
            padding: 10px;
            background: #fff;
            border-right: 4px solid ${props => props.theme.colors.blue};
        }
        &.active {
            display: flex;
        }
    }
    
`

export default HeaderNav