import { useEffect, useState } from "react"
import { UseFormRegister, UseFormWatch } from "react-hook-form"
import { Checkbox } from "src/Components/Atoms/Form/checkbox"
import { fetchAreas, fetchCities, fetchSchools } from "src/Data/fetchSearchParam"
import { SearchFromInterface } from "src/Types/form.d"
import styled from "styled-components"


export const SearchFromArea = (
    {register,
     watch} : 
     {register : UseFormRegister<SearchFromInterface>,
        watch : UseFormWatch<SearchFromInterface>
    }
) => {

    const [cities, setCity] = useState<AreaDataType[]>()
    const [areas, setArea] = useState<AreaDataType[]>()
    const [schools, setSchool] = useState<AreaDataType[]>()

    const watchedCities = watch('cities')
    const watchedAreas = watch('areas')
 
    useEffect(() => {
        fetchCities('').then((res) => {
            if(res.respons === 'success') setCity(res.data)
        })

    },[])

    useEffect(() => {
        let query 
        watchedCities ? query =(`cities=${watchedCities.join(',')}`) : query  = ''

        fetchAreas(query).then((res) => {
            if(res.respons === 'success') setArea(res.data)
        })
    },[watchedCities])
    
    useEffect(() => {
        let cityQuery 
        watchedCities ? cityQuery =(`cities=${watchedCities.join(',')}`) : cityQuery  = ``
        let areaQuery 
        watchedAreas ? areaQuery =(`areas=${watchedAreas.join(',')}`) : areaQuery  = ``
        
        fetchSchools(`${cityQuery}&${areaQuery}`).then((res) => {
            if(res.respons === 'success') setSchool(res.data)
        })
    },[watchedCities, watchedAreas])

    type TToggleSwitch = {
        city : boolean,
        area : boolean,
        schools : boolean
    }

    const [ toggleSwicth, setToggleSwicth ] = useState<TToggleSwitch>({
        city : false,
        area : false,
        schools : false
    })

    const ToggleBtn = ({...props}:{name:string}) => {

        const SButton = styled.button`
            margin-left: 10px;
            cursor: pointer;
            background: ${props => props.theme.colors.blue};
            color: #fff;
            border: none;
            font-weight: bold;
            padding: 5px 12px;
            border-radius: 20px;
        `

        if(props.name === 'city'){
            return (
            <SButton 
                onClick={() => { setToggleSwicth({...toggleSwicth,...{city:!toggleSwicth.city}}) }}>
                    { toggleSwicth.city ? '閉じる -' : '開く +'}
            </SButton>
            )
        }else if(props.name === 'area'){
            return (
                <SButton 
                    onClick={() => { setToggleSwicth({...toggleSwicth,...{area:!toggleSwicth.area}}) }}>
                        { toggleSwicth.area ? '閉じる -' : '開く +'}
                </SButton>
                )
        }else if(props.name === 'schools'){
            return (
                <SButton 
                    onClick={() => { setToggleSwicth({...toggleSwicth,...{schools:!toggleSwicth.schools}}) }}>
                        { toggleSwicth.schools ? '閉じる -' : '開く +'}
                </SButton>
                )
        }else{
            return(<></>)
        }

    }

    return (
        <>
        <dl className="formGroup">
            <dd>市町村 <ToggleBtn name="city"></ToggleBtn></dd>
            <dt className={`checkboxs toggle ${toggleSwicth.city ? 'active' : ''}`}>
                { cities && 
                cities.map((item,i) => (
                    <Checkbox
                        register={register}
                        registerName={'cities'}
                        value={item.name}
                        id={item.id}
                        key={item.id}
                        name={item.name}
                    ></Checkbox>
                ))
                }
            </dt> 
        </dl>
        <dl className="formGroup">
            <dd>地区 <ToggleBtn name="area"></ToggleBtn></dd>
            <dt className={`checkboxs toggle ${toggleSwicth.area ? 'active' : ''}`}>
                { areas && 
                    areas.map((item,i) => (
                        <Checkbox
                            register={register}
                            registerName={'areas'}
                            value={item.name}
                            id={item.id}
                            key={item.id}
                            name={item.name}
                        ></Checkbox>
                    ))
                }
            </dt> 
        </dl>
        <dl className="formGroup">
            <dd>学校区 <ToggleBtn name="schools"></ToggleBtn></dd>
            <dt className={`checkboxs toggle ${toggleSwicth.schools ? 'active' : ''}`}>
                { schools && 
                    schools.map((item,i) => (
                        <Checkbox
                            register={register}
                            registerName={'schools'}
                            value={item.name}
                            id={item.id}
                            key={item.id}
                            name={item.name}
                        ></Checkbox>
                    ))
                }
            </dt> 
        </dl>
        </>
    )
}

