import { PropsWithChildren } from "react";
import styled from "styled-components";


const ContentsTitle = ({children}:PropsWithChildren) => {

    return (
        <STitlte>
            {children}
        </STitlte>
    )
}

const STitlte = styled.h2`
    font-size: ${props => props.theme.fontSize.contetnsTitle};
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    padding-left: 24px;
    position: relative;
    &::before{
        content: '';
        width: 12px;
        height: 12px;
        background: #E0E0E0;
        position: absolute;
        top: 0;
        left: 0;
    }
    &::after{
        content: '';
        width: 12px;
        height: 12px;
        background: #0033A0;
        position: absolute;
        top: 5px;
        left: 5px;
    }
`

export default ContentsTitle