import { useEffect, useState } from "react"
import { BukkenCardChintai } from "src/Components/Molecules/Card/bukkenCard"
import { fetchChintaiIndex } from "src/Data/fetchChintai"
import { ChintaiIndex, ChintaiRespons } from "src/Types/bukken.d"
import styled from "styled-components"
import { useLocation } from 'react-router-dom';
import { PagerDefaultLimit, getInitialItems, getNextItemsFunction } from "src/utils/pegerItems"
import { Button, ButtonMore } from "src/Components/Atoms/Button"
import { Pager } from "../Pager"


const ChinaiBukkens = ({update}:{update:number}) => {

    const pageLimit = 20
    const [ chintais, setChintais ] = useState<ChintaiIndex[]>()
    const [ res, setRes ] = useState<ChintaiRespons>({
        respons: 'invalid',
        data : []
    })


    const locationQuery = useLocation().search
    

    useEffect(() => {
        initLoad()
       
    }, [update])

    const initLoad =  async () => {
        let query 
        locationQuery ? query = locationQuery : query = ''
        const res =  await fetchChintaiIndex(query).then()
        if(res.respons === 'success') {
            setRes(res)
            setChintais(res.data.slice(0, pageLimit))
        } else{}
    }

    const handlePager = (active) => {
        const limit = pageLimit * (active + 1)
        setChintais(res.data.slice(active * pageLimit, limit))
    }


    return(
        <Pager 
            handlePager={handlePager} 
            pageLimit={pageLimit} 
            objextCount={res.data.length}>
            <SChinaiBukkens className="chintai">
                {
                    chintais && 
                    chintais.map((c, i) => (
                        <li key={c.id}>
                            <BukkenCardChintai chintai={c}/>
                        </li>
                    ))
                
                }
            </SChinaiBukkens>
        </Pager>
    )
}

const SChinaiBukkens = styled.ul`
    
`

export default ChinaiBukkens;