import { useEffect, useState } from "react"
import {  BukkenCardLand } from "src/Components/Molecules/Card/bukkenCard"
import {  LandIndex, LandRespons } from "src/Types/bukken.d"
import styled from "styled-components"
import { useLocation } from 'react-router-dom';
import { fetchLandIndex } from "src/Data/fetchLand";
import { PagerDefaultLimit, getInitialItems, getNextItemsFunction } from "src/utils/pegerItems";
import { ButtonMore } from "src/Components/Atoms/Button";
import { Pager } from "../Pager";

const LandBukkens = ({update}:{update:number}) => {
    
    const pageLimit = 20
    const [ data, setData ] = useState<LandIndex[]>()
    const [ lands, setLands ] = useState<LandIndex[]>()
    const [ res, setRes ] = useState<LandRespons>({
        respons: 'invalid',
        data : []
    })

    const [ pagerOffet, setPagerOffet ] = useState<number>(0)
    const pagerLmit = PagerDefaultLimit

    const locationQuery = useLocation().search

    useEffect(() => {
        initLoad()
       
    }, [update])

    const initLoad =  async () => {
        let query 
        locationQuery ? query = locationQuery : query = ''
        const res =  await fetchLandIndex(query).then()
        if(res.respons === 'success') {
            setRes(res)
            setLands(res.data.slice(0, pageLimit))
        } else{}
    }

    const handlePager = (active) => {
        const limit = pageLimit * (active + 1)
        setLands(res.data.slice(active * pageLimit, limit))
        
    }

    return (
        <Pager 
        handlePager={handlePager} 
        pageLimit={pageLimit} 
        objextCount={res.data.length}>
        <SLandBukkens className="land">
            {
                lands && 
                lands.map((b, i) => (
                    <li key={b.id}>
                        <BukkenCardLand land={b}/>
                    </li>
                ))
            }
        </SLandBukkens>
        </Pager>
    )
}

const SLandBukkens = styled.ul`
    
`

export default LandBukkens;