import { Button } from "src/Components/Atoms/Button"
import SubmitFrom from "src/Components/Atoms/Form/submit"
import FormTemplate from "src/Components/Templates/formTemplate"
import { KaitoriApartInterFace } from "src/Types/form.d"



export const KaitoriApartFormConfirm = ({ input, confirmCancel } : {
    input : KaitoriApartInterFace,
    confirmCancel : () => void
}) => {

    return (
        <FormTemplate>
            <dl className="formGroup">
                <dt><label>物件名</label></dt>
                <dd>
                    {input.apartName}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>物件住所</label></dt>
                <dd>
                    {input.bukkenAddress}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>間取り</label></dt>
                <dd>
                   {input.apartLayout}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>建物面積 (坪もしくは㎡)</label></dt>
                <dd >
                    {input.apartArea} {input.apartAreaUnit}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>築年数（もしくは築年月）</label></dt>
                <dd className="inputAge">
                    {input.apartAge}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>現況</label></dt>
                <dd>
                    {input.genkyo}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>備考</label></dt>
                <dd>
                    {input.note}
                   
                </dd>
            </dl>
            <h3 className="m-t-30">お客様の情報</h3>
            <dl className="formGroup">
                <dt><label>お名前</label></dt>
                <dd>
                {input.name}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>メールアドレス</label></dt>
                <dd>
                {input.email}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>電話番号</label></dt>
                <dd>
                {input.tel}
                </dd>
            </dl>
            <dl className="formGroup">
                <dt><label>住所</label></dt>
                <dd>
                {input.address}
                </dd>
            </dl>
            <SubmitFrom value="送信"/>
            <div className="m-t-20 al-c">
                <Button onClick={confirmCancel} color="blue"> ← 戻る</Button>
            </div>
        </FormTemplate>
    )

}