import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import { SlideGallary } from "src/Components/Molecules/SlideGallary"
import { ChintaiInfo } from "src/Types/bukken.d"
import { formatCurrency, numFormat, textToBr } from "src/utils/textUtils"
import parse from 'html-react-parser';
import { ChintaiColumTable } from "src/Components/Molecules/ColunmTable"
import { Gmap } from "src/Components/Atoms/Gmap"
import { Docs } from "src/Components/Molecules/Docs"



export const  ChintaiBukkenView = ({ bukken } : {bukken: ChintaiInfo }) => {

    const cagories = bukken.search_params.category.split(',')
    console.log(bukken)
    return (
        <>
            <div className="contentsHeader--inner">
            <BreadcrumbNav
                items={[
                    { name:`賃貸物件一覧`, href:'/chintais', active:false },
                    { name: `${bukken.name}`, href:'/chintais', active:true }
                ]}
            ></BreadcrumbNav>
            <Docs docs={bukken.docs}></Docs>
            <ContentsTitle>{bukken.name}</ContentsTitle>
            {
                cagories.map((c,i)=>(<span  className="categorytab" key={`cate-${i}`}>{c}</span>))
            }
            </div>
            <section className="bukken-view">
            <div className="bukken-view--inner">
                <div className="bukkenHeader">
                    <div className="layout">
                        <label>間取り</label>
                        {bukken.search_params.layout}
                    </div>
                    <div className="price">
                        <label>家賃</label>
                        {numFormat(bukken.price)}円
                    </div>
                    <div className="other">
                        <span>敷金：{bukken.security_deposit ? formatCurrency(bukken.security_deposit) : '-'}</span>
                        <span>礼金：{bukken.key_money ? formatCurrency(bukken.key_money) : '-'}</span><br/>
                        <span>住所：{bukken.address} </span>
                        <span>小学校区：{bukken.search_params.jhs}</span>
                    </div>
                    <div className="memo">
                        <h4>物件概要</h4>
                        <p>
                            { parse(textToBr(bukken.summary)) }
                        </p>
                    </div>
                </div>
                <div className="bukken-images">
                    <div>
                        <SlideGallary images={bukken.images} />
                    </div>
                    <div>
                        <SlideGallary images={bukken.madoris} />
                    </div>
                </div>
                {bukken.gmap &&
                <Gmap iframe={bukken.gmap}></Gmap>
                }
                <div className="bukken-info">
                    <ChintaiColumTable detail={bukken}/>
                </div>
                <div className="bukkenHeader">
                    <div className="memo">
                        <h4>初期費用</h4>
                        <p>
                            { parse(textToBr(numFormat(bukken.initial_cost))) }
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}