import styled,{ ThemeProvider } from "styled-components"
import theme from "src/Themes"
import { PropsWithChildren } from "react"
import Header from "../Organisms/Header"
import Footer from "../Organisms/Footer"

export const MaruichiTemplate = (
    {children} : PropsWithChildren) => {

    return (
        <ThemeProvider theme={theme}>
            <STemplate>
                <Header></Header>
                { children }
                <Footer></Footer>
            </STemplate>
        </ThemeProvider>
    ) 
}

const STemplate = styled.div`
    padding-top: 200px;
    img{ max-width: 100%; }
    .inner,[class*=--inner]{max-width: 1140px; padding: 0 20px;}
    figure{margin: 0;}
    .more_view{
        text-align: center;
        padding: 30px 0 60px;
    }
    .viewclose--inner{
        padding: 20px 20px;
        text-align: right;
    }
    @media (max-width: ${props  => props.theme.spWidth}) {
        padding-top: 180px;
    }
`