
import { useState } from "react";
import { useForm } from "react-hook-form";
import { KaitoriApartForm } from "src/Components/Organisms/KaitoriForm/Apart";
import { fetchContactKaitori } from "src/Data/fetchContact";
import { FormStateType, KaitoriApartInterFace } from "src/Types/form.d";
import { KaitoriApartFormConfirm } from "../KaitoriForm/Apart/confirm";
import { KaitoriApartFormSend } from "../KaitoriForm/Apart/send";

export const KaitoriApart = () => {
    
    const { register, formState:{errors}, watch, handleSubmit } = useForm<KaitoriApartInterFace>({
        defaultValues: {
            category : 'マンション・アパート',
            apartAreaUnit : '㎡'
        }
    })
    const [ input, setInput ] = useState<KaitoriApartInterFace>()
    const [ formState, setFormState ] = useState<FormStateType>('input')
    
    const onFormSubmit = (currentInput) => {
        window.scroll(0,0)
        switch(formState){
            case 'input':
                setFormState('confirm')
                setInput(currentInput)
                break;
            case 'confirm' : 
                fetchContactKaitori(currentInput).then((res) => {
                    setFormState('send')
                })
                break;
        }
    }

    const confirmCancel = () => {
        setFormState('input')
    }


    return (
        <>
            { formState === 'input' &&
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <KaitoriApartForm
                        register={register}
                        errors = {errors}
                        watch = {watch}
                    />
                </form>
            }
            {
                formState === 'confirm' &&
                <form onSubmit={handleSubmit(onFormSubmit)}>
                <KaitoriApartFormConfirm input={input} confirmCancel={confirmCancel}/>
                </form>
            }
            {
                formState === 'send' &&
                <KaitoriApartFormSend/>
            }
        </>
        )
}