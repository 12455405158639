import { PropsWithChildren, useState } from "react"
import styled from "styled-components"

export interface IPagerProps {
    objextCount: number,
    pageLimit : number,
    handlePager : (activPage:number) => void
}

export const Pager = ({children,...props}: PropsWithChildren<IPagerProps>) => {
    
    const [ pagerActive, setPagerActive ] = useState<number>(0)

    const handlePager = (active) => {

        setPagerActive(active)
        props.handlePager(active)
       
    }

    const nextPage = (pagerNum : number ) => {  
        if(pagerActive + 1 < pagerNum){
            handlePager(pagerActive + 1)
        }
    }

    const prevPage = () => {
        if(pagerActive - 1 >= 0){
            handlePager(pagerActive - 1)
        }
    }

    const pagerNum = props.objextCount / props.pageLimit

    const list = [];
    const lowerList = [];
    for (let i = 0; i < pagerNum; i++) {
        list.push(<li className={`${i === pagerActive ? 'active': ''}`} key={`page-${i}`}><a onClick={()=>{handlePager(i)}}>{i+1}</a></li>)
        lowerList.push(<li className={`${i === pagerActive ? 'active': ''}`} key={`page-${i}`}><a onClick={()=>{handlePager(i); ;  window.scrollTo(0,0)}}>{i+1}</a></li>)
    }

    

    const isNext = (pagerActive+1) * props.pageLimit < props.objextCount;
    return(
        <>
        <SPager>
            <li className={`${pagerActive === 0 ? 'disabled': ''}`}><a onClick={()=>{ prevPage() }}>&laquo; 前の20件</a></li>
            {list}
            <li className={`${isNext ? '': 'disabled'}`}><a onClick={()=>{ nextPage(pagerNum)}}>次の20件 &raquo;</a></li>
        </SPager>
        <SContnts>
            {children}
        </SContnts>
        <SPager>
            <li className={`${pagerActive === 0 ? 'disabled': ''}`}>
                <a onClick={()=>{ prevPage();  window.scrollTo(0,0) }}>&laquo; 前の20件</a></li>
            {lowerList}
            <li className={`${isNext ? '': 'disabled'}`}>
                <a onClick={()=>{ nextPage(pagerNum);  window.scrollTo(0,0) }}> 次の20件 &raquo;</a>
            </li>
        </SPager>
        </>
    ) 
}
const SContnts = styled.div`
    padding-top: 30px;
`
const SPager = styled.div`
     list-style: none;
    padding:20px 0 10px;
    display: flex;
    justify-content: center;
    
    li {
        margin: 0 5px;
    }

    li a {
        text-decoration: none;
        color: #333;
        padding: 5px 10px;
        border: 1px solid #ccc;
        background-color: #f8f8f8;
        cursor: pointer;
        display: block;
    }

    li.active a {
        color: #fff;
        background-color: ${props => props.theme.colors.red};
        border-color: ${props => props.theme.colors.red};
    }

    li.disabled a {
        color: #ccc;
        cursor: not-allowed;
    }

    li a:hover:not(.active):not(.disabled) {
        background-color: #e9ecef;
        border-color: #adb5bd;
    }
    @media screen and (max-width: 767px){
        padding:20px 0 0px;
        li {
            margin-bottom: 10px;
        }
        flex-wrap: wrap;
    }

    
`