import { UseFormRegister } from "react-hook-form"
import styled from "styled-components"


export const Checkbox = ({ register, registerName, id, name, value, ...props }:
    {   register  : UseFormRegister<any>,
        registerName: string,
        id:string,
        name : string,
        value : string
    }) => {


    return (
        <SCheckbox>
            <input 
                type="checkbox" 
                {...register(registerName)} 
                value={value}
                id={id}/>
            <label 
            className="checkbox" 
            htmlFor={id}>{name}</label>
         </SCheckbox>
    )
}

const SCheckbox = styled.div`
    input[type=checkbox] {
        display: none;
    }
    .checkbox {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 5px 30px;
        position: relative;
        width: auto;
    }
    .checkbox::before {
        background: #fff;
        border: 1px solid #231815;
        content: '';
        display: block;
        height: 16px;
        left: 5px;
        margin-top: -11px;
        position: absolute;
        top: 50%;
        width: 16px;
    }
    .checkbox::after {
        border-right: 3px solid ${props => props.theme.colors.blue};
        border-bottom: 3px solid ${props => props.theme.colors.blue};
        content: '';
        display: block;
        height: 9px;
        left: 10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 5px;
    }
    input[type=checkbox]:checked + .checkbox::after {
        opacity: 1;
    }
`