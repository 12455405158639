import { useState } from "react";
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle";
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav";
import { KaitoriApart } from "src/Components/Organisms/Kaitori/Apart";
import { KaitoriLand } from "src/Components/Organisms/Kaitori/Land";
import { KaitoriKodate } from "src/Components/Organisms/Kaitori/kotade";
import { KaitoriApartForm } from "src/Components/Organisms/KaitoriForm/Apart";
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate";
import styled from "styled-components";


export const KaitoriPage = () => {
    document.title = '買取依頼 | 丸一不動産 宮崎県日向市の総合不動産会社'

    const [input, setInput] = useState(0)
    const [onConfirm, setOnConfrim] = useState(0)

    const [actvieTab, setActvieTab] =  useState(1) 

    
    return (
        <MaruichiTemplate>
            <div className="contentsHeader--inner">
               <BreadcrumbNav 
                    items={[
                        { name:`買取相談`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                <ContentsTitle>買取相談 : 戸建</ContentsTitle>
            </div>
            <SKaitori>
                <div className="contents--inner">
                    <STabNav>
                        <li data-tab="1" onClick={() => {setActvieTab(1)}} 
                            className={ actvieTab === 1 ? 'active' : ''}>戸建</li>
                        <li data-tab="2" onClick={() => {setActvieTab(2)}}
                            className={ actvieTab === 2 ? 'active' : ''}>マンション</li>
                        <li data-tab="3" onClick={() => {setActvieTab(3)}}
                            className={ actvieTab === 3 ? 'active' : ''}>土地</li>
                    </STabNav>
                    {  actvieTab === 1 &&
                        <KaitoriKodate></KaitoriKodate>
                    }
                    {  actvieTab === 2 &&
                        <KaitoriApart/>
                    }
                    { actvieTab === 3&&
                        <KaitoriLand/>
                    }   
                </div>
            </SKaitori>
        </MaruichiTemplate>
    )
}

const SKaitori = styled.div`
    h3{
        background: #fff;
        padding:10px 20px;
        margin-bottom: 20px;
        border-left: 4px ${props => props.theme.colors.blue} solid;
    }
    .contents--inner{
        background: ${props => props.theme.colors.grayC};
        padding: 40px 20px;
        margin-bottom: 40px;
    }
`

const STabNav = styled.ul`
display: flex;
justify-content: space-between;
margin-bottom: 30px;
li{
    background: #fff;
    color: #000;
    text-align: center;
    flex-basis: 32%;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    &:hover{
        background: ${props => props.theme.colors.blue};
        color:#fff;
    }
    &.active{
        background: ${props => props.theme.colors.blue};
        color:#fff;
    }
}
`;