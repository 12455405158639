import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputForm } from "src/Components/Atoms/Form/input"
import SubmitFrom from "src/Components/Atoms/Form/submit";
import FormTemplate from "src/Components/Templates/formTemplate";
import { SearchFromInterface } from "src/Types/form.d";



export const SearchFormKeyword = (
    {queryParams, formReset}:
    {queryParams : URLSearchParams,
     formReset ?: boolean
    }
) => {
    const { register, setValue } = useForm<SearchFromInterface>({
        defaultValues: { }
    });

    useEffect(() => {
        if(formReset) {
            setValue('keyword', '')
        }else{
            setValue('keyword', queryParams.get('keyword'))
        }
    },[formReset])

    return (
        <FormTemplate>
        <form>
            <dl className="formGroup">
                <dt>キーワード検索</dt>
                <dd className="inpuKeyword">
                    <InputForm 
                        register={register} 
                        registerName="keyword"
                        type="text" />
                    <input type="submit" value="検索"/>
                </dd> 
            </dl>
        </form>
        </FormTemplate>
    )
}