import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "src/Components/Atoms/Button"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import ChinaiBukkens from "src/Components/Organisms/Bukkens/chintaiBukkens"
import { CategoriesChintai } from "src/Components/Organisms/Categories/chintai"
import { SearchFormChintai } from "src/Components/Organisms/SearchForm/chintai"
import { SearchFormKeyword } from "src/Components/Organisms/SearchForm/keyword"
import { SearchParamDisplay } from "src/Components/Organisms/SearchForm/searchParamDisplay"
import SortNav from "src/Components/Organisms/SortNav"
import { BukkenTemplate } from "src/Components/Templates/bukkenTemplate"
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate"


export const ChintaisPage = () => {
    document.title = '賃貸情報 | 丸一不動産 宮崎県日向市の総合不動産会社'

    const [ update, setUpdate ] = useState<number>(0)
    const [ searchReset , setSearchReset] = useState<boolean>(false)
    const [ saerchNavOpen, setSaerchNavOpen] = useState<boolean>(false)
    
    const navivate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleUpdate = () => {
        setSaerchNavOpen(false)
        setUpdate(update+1)
    }

    const resetLoad = () => {
        navivate(`/chintais`);
        setSearchReset(true)
        setUpdate(update+1)
    }

    return (
        <MaruichiTemplate>
            <BukkenTemplate>
            <div className="contentsHeader--inner">
                <BreadcrumbNav 
                    items={[
                        { name:`賃貸物件一覧`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                <ContentsTitle>賃貸物件一覧</ContentsTitle>
            </div>
            <div className="bukken-contents--inner">
                <main>
              
                    <div className="box categories">
                       <CategoriesChintai handleUpdate={handleUpdate} />
                    </div>
                    <SortNav handleUpdate={handleUpdate} type={'chintais'}></SortNav>
                    <div className="searchbtn-wrap">
                        <Button onClick={() => { setSaerchNavOpen(!saerchNavOpen) }} color="red">検索条件設定</Button>
                    </div>
                    <SearchParamDisplay queryParams={queryParams} reset={resetLoad} type="chintai"/>
                    <SearchFormKeyword queryParams={queryParams} formReset={searchReset}></SearchFormKeyword>
                    <ChinaiBukkens update={update}></ChinaiBukkens>
                </main>
                <aside className={ saerchNavOpen ? 'active' : '' }>
                    <div className="searchbtn-wrap">
                        <Button onClick={() => { setSaerchNavOpen(false) }} color="blue">× 閉じる</Button>
                    </div>
                    <SearchFormChintai 
                        handleUpdate={handleUpdate}
                        queryParams={queryParams}
                        formReset={searchReset}
                        ></SearchFormChintai>
                </aside>
            </div>
            </BukkenTemplate>
        </MaruichiTemplate>
    )

}


