import { PropsWithChildren } from "react"
import styled from "styled-components"


const CategoriesTemplate = ({children, ...props}:PropsWithChildren) => {

    return (
        <SCategories> 
            {children}
        </SCategories>
    )
}

const SCategories = styled.div`
    background: ${props => props.theme.colors.grayB};
    padding: 10px 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > * {
        margin: 0;
        margin-right: 2%;
        flex-basis: 48%;
        margin-bottom: 10px;
        &:nth-child(2n){
            margin-right: 0;
        }
    }
`

export default CategoriesTemplate;