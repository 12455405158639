const fontSize = {
    input:'16px',
    submit: '16px',
    label: '1.5rem',
    h3: '1.8rem',
    tel: '2.4rem',
    contetnsTitle: '1.8rem',
    time: '1.6rem',
    headerNav: '1.6rem',
    footerNav: '1.6rem',
    cardTitle: '1.6rem',
    newsTitle: '1.6rem',
    btn: '1.3rem',
    info: '1.3rem',
    newsDate: '1.3rem',
    sortNav: '1.5rem',
    bukkenLabel: '1.2rem',
    telSp: '2.0rem',
    timeSp: '1.2rem'
}

export default fontSize