import { PropsWithChildren } from "react"
import styled from "styled-components"


const SearchFormTemplate = (    
    {children} : PropsWithChildren) => {

    return (
        <STemplate>
            { children }
        </STemplate>
    ) 
}
const STemplate = styled.div`
    
    dl.formGroup{
        dd{
            font-weight: bold;
            padding:10px 0;
        }
        dt{
           
            &.inputPrice{
                display: flex;
                justify-content: space-between;
                & > div{
                    flex:1;
                    box-sizing: border-box;
                }
                span{
                    flex-basis: 200px;
                    padding-left: 5px;
                }
                
            }
            &.checkboxs{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                & > div{
                    flex-basis: 49%;
                    margin-right: 2%;
                    margin-bottom: 2%;
                    background: ${props => props.theme.colors.grayC};
                    padding: 3px;
                    margin-left: 0;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
            }
            &.toggle{
                opacity: 0;
                height: 0px;
                overflow: hidden;
                &.active{
                    opacity: 1;
                    height: auto;
                    overflow: auto;
                }
            }
        }
    }

`

export default SearchFormTemplate