import axios from 'axios'
import { ChintaiInfo, ChintaiInfoRespons, ChintaiRespons } from 'src/Types/bukken.d';

export const fetchChintaiIndex = async (query):Promise<ChintaiRespons> => {
    
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/chintais/search.json${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
};

export const fetchChintaiInfo =  async (cId:string):Promise<ChintaiInfoRespons> => {

  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/chintais/preview.json?id=${cId}`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }

}

export const fetchChintaiRecoms = async ():Promise<ChintaiRespons> => {
    
  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/chintais/recoms.json`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
};