import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useLocation, useParams } from "react-router-dom"
import { Button } from "src/Components/Atoms/Button"
import { BukkenForm } from "src/Components/Organisms/BukkenForm"
import { BukkenFormComfirm } from "src/Components/Organisms/BukkenForm/confirm"
import { BukkenFormSend } from "src/Components/Organisms/BukkenForm/send"
import { BaibaiBukkenView } from "src/Components/Organisms/BukkenView/baibai"
import { BukkenTemplate } from "src/Components/Templates/bukkenTemplate"
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate"
import { fetchBaibaiInfo } from "src/Data/fetchBaibai"
import { fetchContactBukken } from "src/Data/fetchContact"
import { BaibaiInfo } from "src/Types/bukken.d"
import { BukkenFormInterface, FormStateType } from "src/Types/form.d"



export const BaibaiViewPage = () => {

    const { id } = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [formState, setFormState] = useState<FormStateType>('input')
    const [bukken, setBukken] = useState<BaibaiInfo>()
    const { register, watch, handleSubmit, formState: { errors }, setValue } = useForm<BukkenFormInterface>({
        defaultValues:{
            url : `baibais/${id}`,
        }
    })
    const [ inputData, setInputData ] = useState<BukkenFormInterface>()


    useEffect(() => {
        fetchBaibaiInfo(id).then((res) => {
            if(res.respons === 'success'){
                 setBukken(res.data)
                 setValue('bukken_name', res.data.name)
                 document.title = `${res.data.name} - 新築・中古情報 | 丸一不動産 宮崎県日向市の総合不動産会社`
            }
        })
    },[])

    const onFormSubmit = (currentInput) => {
        window.scroll(0,0)
        switch(formState){
            case 'input':
                setFormState('confirm')
                setInputData(currentInput)
                break;
            case 'confirm' : 
                fetchContactBukken(currentInput).then((res) => {
                    setFormState('send')
                })
                break;
        }
    }

    const confirmCancel = () => {
        setFormState('input')
    }

    return(
        <MaruichiTemplate>
            <div className="viewclose--inner">
                {
                    queryParams.get('window') === 'on' &&
                    <Button onClick={() => { window.close() }} color="red">× 物件情報を閉じる</Button>
                }
            </div>
            <BukkenTemplate>
            {
                bukken && formState === 'input'  &&
                <>
                <BaibaiBukkenView bukken={bukken}></BaibaiBukkenView>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <BukkenForm 
                        type="chintais" 
                        id={bukken.id}
                        register={register}
                        watch={watch}
                        errors={errors}
                        ></BukkenForm>
                </form>
                </>
            }
             {
                formState === 'confirm' && 
                <form onSubmit={handleSubmit(onFormSubmit)}>
                <BukkenFormComfirm input={inputData} confirmCancel={confirmCancel}/>
                </form>
            }
            {
                 formState === 'send' && 
                 <BukkenFormSend></BukkenFormSend>
            }
            </BukkenTemplate>
        </MaruichiTemplate>
    )

}