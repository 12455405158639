import CategoriesTemplate from "src/Components/Templates/CategoriesTemplate"

import CategoryCard from "src/Components/Molecules/Card/categoryCard"
import { useLocation, useNavigate } from "react-router-dom"


export const CategoriesBaibai = ({handleUpdate}:{handleUpdate:()=>void}) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    const addQueryCategroy = (cate:string) => {
        const currentUrlParams = new URLSearchParams(location.search); // 現在のURLのクエリパラメータを取得
        currentUrlParams.set('cate', cate); // `sort=price_upper`をセットまたは上書き
    
        // URLを更新 (現在のパスに新しいクエリパラメータを付与)
        navigate(`/baibais?${currentUrlParams.toString()}`);
        handleUpdate()

    }   

    return (
        <CategoriesTemplate>
            <CategoryCard 
                onClick={() => addQueryCategroy('事業用')}
                img="c_jumusho.jpg"
                name="事業所用物件"
                dd="事務所、倉庫、飲食店等、事業用物件を検討の方へ"
            />
            <CategoryCard 
                onClick={() => addQueryCategroy('新築・建売')}
                img="c_sinchiku.jpg"
                name="新築・建売物件"
                dd="新築一戸建て・分譲一戸建て・分譲住宅の購入を検討の方へ"
            />
        </CategoriesTemplate>
    )  
}