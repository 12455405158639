import { PropsWithChildren, useEffect, useState } from "react"
import {BukkenCardBaibai, BukkenCardChintai, BukkenCardLand} from "src/Components/Molecules/Card/bukkenCard"
import { fetchBaibaiRecoms } from "src/Data/fetchBaibai"
import { fetchChintaiRecoms } from "src/Data/fetchChintai"
import { fetchLandRecoms } from "src/Data/fetchLand"
import { BaibaiIndex, ChintaiIndex, LandIndex } from "src/Types/bukken.d"
import styled from "styled-components"


const OsusumeTab = ({children}:PropsWithChildren) => {
    
    const [active, setActive] = useState<string>('chintai')
    
    const [chintais, setChintais] = useState<ChintaiIndex[]>()
    const [baibais, setBaibais] = useState<BaibaiIndex[]>()
    const [lands, setLands] = useState<LandIndex[]>()

    useEffect(() => {
        fetchChintaiRecoms().then((res) => {
            if(res.respons === 'success') setChintais(res.data)
        })
        fetchBaibaiRecoms().then((res) => {
            if(res.respons === 'success') setBaibais(res.data)
        })
        fetchLandRecoms().then((res) => {
            if(res.respons === 'success') setLands(res.data)
        })
    },[])
    

    return (
        <STabs>
            <nav className="osusumeTabNav">
                <div 
                    className={`nav chintai ${active === 'chintai' ? 'active' : ''}`}
                    onClick={()  => { setActive('chintai') }}
                >賃貸</div>
                <div 
                    className={`nav land ${active === 'land' ? 'active' : ''}`}
                    onClick={()  => { setActive('land') }}
                >土地</div>
                <div 
                    className={`nav baibai ${active === 'baibai' ? 'active' : ''}`}
                    onClick={()  => { setActive('baibai') }}
                >中古住宅</div>
            </nav>
            <div className="osusumeTabContents">
                <div className="osusumeHeightWrap">
                    <div className={`tab ${active === 'chintai' ? 'active' : ''}`}>
                        <ul className={`chintai`}>
                            {
                                chintais && 
                                chintais.map((c) => ( 
                                    <li key={c.id}>
                                        <BukkenCardChintai chintai={c}/>
                                    </li>
                                ))
                            }
                        </ul>  
                        <div className="viewMore">
                            <SLink 
                                href="https://maru-fudousan.jp/chintais"
                                className="red">そのほかの物件を見る</SLink>
                        </div>
                    </div>
                    <div className={`tab ${active === 'land' ? 'active' : ''}`}>
                        <ul className={`land`}>
                            {
                                lands && 
                                lands.map((c) => ( 
                                    <li key={c.id}>
                                        <BukkenCardLand land={c}/>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="viewMore">
                            <SLink 
                                href="https://maru-fudousan.jp/lands"
                                className="red">そのほかの物件を見る</SLink>
                        </div>
                    </div>
                    <div className={`tab ${active === 'baibai' ? 'active' : ''}`} >
                        <ul className={`baibai`}>
                            {
                                baibais && 
                                baibais.map((c) => ( 
                                    <li key={c.id}>
                                        <BukkenCardBaibai baibai={c}/>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="viewMore">
                            <SLink 
                                href="https://maru-fudousan.jp/baibais"
                                className="red">そのほかの物件を見る</SLink>
                        </div>
                    </div>
                </div>
            </div>
        </STabs>
    )
}

const STabs = styled.div`
 .osusumeTabNav{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .nav{
        text-align: center;
        flex-basis: 32%;
        padding: 8px 4px;
        letter-spacing: 0.2rem;
        cursor: pointer;
        &.chintai{
            border: solid 1px ${props => props.theme.colors.chintai };
            &.active,&:hover{
                background: ${props => props.theme.colors.chintai };
                color: #fff;
            }
        }
        &.baibai{
            border: solid 1px ${props => props.theme.colors.baibai };
            &.active,&:hover{
                background: ${props => props.theme.colors.baibai };
                color: #fff;
            }
        }
        &.land{
            border: solid 1px ${props => props.theme.colors.land };
            &.active,&:hover{
                background:${props => props.theme.colors.land };
                color: #fff;
            }
        }
    }
}

.osusumeTabContents{
    .tab{
        display: none;
        &.active{
            display: block;
        }
    }
    ul{
        overflow: scroll;
        max-height: 800px;
        margin-bottom: 30px;
        li{
            margin-bottom: 10px;
        }
    }
    .viewMore{
        text-align: right;
    }
   
}

.osusumeHeightWrap{
    
    @media (max-width: ${props  => props.theme.spWidth}) {
        max-height: 100%;
    }
}

`

const SLink = styled.a`
    border: solid 1px ${props => props.theme.colors.grayB};
    display: inline-block;
    padding:10px 20px;
    font-size: ${props => props.theme.fontSize.btn};
    font-weight: bold;
    &.blue{
        border: solid 1px ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.blue};
        &:hover{
            background:${props => props.theme.colors.blue};
            color: #fff;
        }
    }
    &.red{
        border: solid 1px  ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
        &:hover{
            background:${props => props.theme.colors.red};
            color: #fff;
        }
    }
`

export default OsusumeTab