import { Link } from "react-router-dom"
import styled from "styled-components"

const SLogo = styled.div`

    img{ max-width: 450px !important; }
    @media (max-width: ${props  => props.theme.spWidth}) {
        img{ width: 320px  !important;}
    }
`

const Logo = () => {

    return (
        <SLogo>
            <Link to={`/`}>
                <img
                    src={`./images/logo.png`}
                    alt="丸一不動産"
                />
            </Link>
        </SLogo>
    )
}

export default Logo