import { FieldErrors, UseFormRegister, UseFormWatch, useForm } from "react-hook-form"
import { InputForm } from "src/Components/Atoms/Form/input"
import { SelectForm, SelectValues } from "src/Components/Atoms/Form/select"
import SubmitFrom from "src/Components/Atoms/Form/submit"
import TextAraeForm from "src/Components/Atoms/Form/textarea"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import FormTemplate from "src/Components/Templates/formTemplate"
import { BukkenFormInterface } from "src/Types/form.d"

interface BukkenFormProps{
    type : string,
    id : string,
    register : UseFormRegister<BukkenFormInterface> 
    watch : UseFormWatch<BukkenFormInterface>
    errors? : FieldErrors<BukkenFormInterface>
}

export const BukkenForm = ({type, id, register ,watch, errors}:BukkenFormProps) => {


    const selectCategory:SelectValues = [
        {name: '見学希望', value: '見学希望'},
        {name: '来店相談', value: '来店相談'},
        {name: 'その他', value: 'その他'}
    ]

    return (
        <FormTemplate>
            <section className="bukken-form">
                <div className="bukken-form--inner">
                    <ContentsTitle>お問合せフォーム</ContentsTitle>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">お問い合わせ種別</label>
                        </dt>
                        <dd className="select">
                            <SelectForm
                                values={selectCategory}
                                register={register}
                                registerName="category"
                                watch={watch}
                                className="w100"
                            ></SelectForm>
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">名前</label>
                        </dt>
                        <dd className="inputName">
                            <InputForm register={register} registerName="name1" type="text"  options={{required: true}}/>
                            <InputForm register={register} registerName="name2" type="text"  options={{required: true}}/>
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">住所</label>
                        </dt>
                        <dd className="inputEmail">
                            <InputForm register={register} registerName="address" type="text"  options={{required: true}}/>
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">電話番号</label>
                        </dt>
                        <dd className="inputEmail">
                            <InputForm register={register} registerName="tel" type="text"  options={{required: true}}/>
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label className="required">E-mail</label>
                        </dt>
                        <dd className="inputEmail">
                            <InputForm register={register} registerName="email" type="email"  options={{required: true}}/>
                        </dd>
                    </dl>
                    <dl className="formGroup">
                        <dt>
                            <label>問合せ内容</label>
                        </dt>
                        <dd className="textarea">
                           <TextAraeForm
                            register={register}
                            name="info"
                           ></TextAraeForm>
                        </dd>
                    </dl>
                    <div className="privacyCheck">
                        <input 
                            type="checkbox" 
                            name="privacy"
                            id="privacy"
                            />
                        <label className="checkbox" htmlFor='privacy'>個人情報取り扱い事項に同意します</label>
                    </div>
                    <SubmitFrom
                        value="確認画面へ"
                    ></SubmitFrom>
            </div>
        </section>
        </FormTemplate>
    )
}