
import { useForm } from "react-hook-form";
import { FormStateType, KaitoriLandInterFace } from "src/Types/form.d";
import { KaitoriLandForm } from "../KaitoriForm/Land";
import { useState } from "react";
import { fetchContactKaitori } from "src/Data/fetchContact";
import { KaitoriLandFormSend } from "../KaitoriForm/Land/send";
import { KaitoriLandFormConfirm } from "../KaitoriForm/Land/confirm";

export const KaitoriLand = () => {
    
    const { register, formState:{errors}, watch, handleSubmit } = useForm<KaitoriLandInterFace>({
        defaultValues : {
            category : '土地'
        }
    })
    const [ input, setInput ] = useState<KaitoriLandInterFace>()
    const [ formState, setFormState ] = useState<FormStateType>('input')
    
    const onFormSubmit = (currentInput) => {
        window.scroll(0,0)
        switch(formState){
            case 'input':
                setFormState('confirm')
                setInput(currentInput)
                break;
            case 'confirm' : 
                fetchContactKaitori(currentInput).then((res) => {
                    setFormState('send')
                })
                break;
        }
    }

    const confirmCancel = () => {
        setFormState('input')
    }


    return (    
        <>

        { formState === 'input' &&  
            <form onSubmit={handleSubmit(onFormSubmit)}>
            <KaitoriLandForm
                register={register}
                errors = {errors}
                watch = {watch}
            />
            </form>
        } 
        {
            formState === 'confirm' &&
            <form onSubmit={handleSubmit(onFormSubmit)} className="confirm">
                <KaitoriLandFormConfirm input={input} confirmCancel={confirmCancel}/>
            </form>
        }
        {
            formState === 'send' &&
            <KaitoriLandFormSend></KaitoriLandFormSend>
         }
         </>
        )
}