import { useEffect, useState } from "react"
import { BukkenCardBaibai } from "src/Components/Molecules/Card/bukkenCard"
import { BaibaiIndex, BaibaiRespons } from "src/Types/bukken.d"
import styled from "styled-components"
import { useLocation } from 'react-router-dom';
import { fetchBaibaiIndex } from "src/Data/fetchBaibai"
import { PagerDefaultLimit, getInitialItems, getNextItemsFunction } from "src/utils/pegerItems";
import { ButtonMore } from "src/Components/Atoms/Button";
import { Pager } from "../Pager";

const BaibaiBukkens = ({update}:{update:number}) => {
    
    const pageLimit = 20
    const [ baibais, setBaibai ] = useState<BaibaiIndex[]>()
    const [ res, setRes ] = useState<BaibaiRespons>({
        respons: 'invalid',
        data : []
    })


    const locationQuery = useLocation().search

    useEffect(() => {
        initLoad()
       
    }, [update])

    const initLoad =  async () => {
        let query 
        locationQuery ? query = locationQuery : query = ''
        const res =  await fetchBaibaiIndex(query).then()
        if(res.respons === 'success') {
            setRes(res)
            setBaibai(res.data.slice(0, pageLimit))
        } else{}
    }

    const handlePager = (active) => {
        const limit = pageLimit * (active + 1)
        setBaibai(res.data.slice(active * pageLimit, limit))
    }

    return (
    <Pager 
        handlePager={handlePager} 
        pageLimit={pageLimit} 
        objextCount={res.data.length}>
        <SBaibaiBukkens className="baibai">
            {
                baibais && 
                baibais.map((b, i) => (
                    <li key={b.id}>
                        <BukkenCardBaibai baibai={b}/>
                    </li>
                ))
            }
        
        </SBaibaiBukkens>
    </Pager>
    )
}

const SBaibaiBukkens = styled.ul`
    
`

export default BaibaiBukkens;