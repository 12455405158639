import { numFormat } from "src/utils/textUtils";
import styled from "styled-components";
import { Button } from "src/Components/Atoms/Button"

export const SearchParamDisplay = ({queryParams, reset , type}:{
    queryParams: URLSearchParams
    reset: () => void  
    type : string,
}) => {
     
    // パラメータ名と表示名の対応表
    const paramNames = {
        under_price: '家賃下限',
        upper_price: '家賃上限',
        layouts: '間取り',
        cities: '市町村', // URLのクエリパラメータと一致させる必要があるため、'city'から'cities'に修正
        areas: '地区',
        schools: '学校区',
        cate: 'カテゴリ'
    };

    const displayTexts = Object.keys(paramNames).map(key => {
        const value = queryParams.get(key);
        if (value) { 
            if(key === 'under_price' || key === 'upper_price'){
                if(type == 'chintai') {
                    return (
                        <>
                        <label> {paramNames[key]} </label> {`: ${numFormat(value)} 円`}
                        </>
                    );
                }else{
                    return (
                        <>
                        <label> {key === 'under_price' ? '価格下限' : '価格上限' } </label> {`: ${numFormat(value)} 万円`}
                        </>
                    );
                }
            }else{
                return (
                    <>
                    <label> {paramNames[key]} </label> {`: ${value.replace(/,/g, ', ')}`}
                    </>
                );
            }
    
        }else{
            return false
        }
    });

    return(
        <SSearchText>
            {displayTexts.map((text, index) => {
                if(text) return(  <div key={index}>{text}</div> )
            })}
            <Button onClick={reset} color="blue">検索条件リセット</Button>
        </SSearchText>
    )
}

const SSearchText = styled.div`
    background: ${props => props.theme.colors.grayC};
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    > div {
        margin: 0;
        margin-right: 10px;
        padding: 5px;
        margin-bottom: 5px;
        &.none{
            margin: 0;
            display: none;
            background: #000;
        }
        label{
            font-weight: bold;
        }
    }
`
