import { ButtonLink } from "src/Components/Atoms/Button";
import NewsList from "src/Components/Molecules/List/newsList"
import styled from "styled-components";


const News = ({posts}) => {

    const dateConvert  = (date) => {
        const dateData = new Date(date);
		

        return `${dateData.getFullYear()}/${(dateData.getMonth()+1).toString().padStart(2, '0')}/${dateData.getDate()}`
    }

    return (
        <SNews>
        <NewsList>
            {posts.map((post) => (
                
                <li key={post.id}>
                    <a href={post.link}>
                    <span className="date">{dateConvert(post.date)}</span>
                    <span className="title">{post.title.rendered}</span>
                    </a>
                </li>
            ))
            }
        </NewsList>
            <div className="viewMore">
                <SLink 
                    href="https://maru-fudousan.jp/news/"
                    className="red">一覧を見る</SLink>
            </div>
        </SNews>
    )
}

const SNews = styled.div`
    .viewMore{
        margin-top: 20px;
        text-align: right;
    }
`

const SLink = styled.a`
    border: solid 1px ${props => props.theme.colors.grayB};
    display: inline-block;
    padding:10px 20px;
    font-size: ${props => props.theme.fontSize.btn};
    font-weight: bold;
    &.blue{
        border: solid 1px ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.blue};
        &:hover{
            background:${props => props.theme.colors.blue};
            color: #fff;
        }
    }
    &.red{
        border: solid 1px  ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
        &:hover{
            background:${props => props.theme.colors.red};
            color: #fff;
        }
    }
`

export default News;