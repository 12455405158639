import { BrowserRouter, Routes, Route} from "react-router-dom";
import { TopPage } from './Pages/Top'
import { ChintaisPage } from './Pages/Chintais'
import { ChintaisiViewPage } from './Pages/Chintais/view'
import { BaibaisPage } from './Pages/Baibais'
import { BaibaiViewPage } from './Pages/Baibais/view'
import { LandsPage } from './Pages/Lands'
import { LandViewPage } from './Pages/Lands/view'
import { CompanyPage } from './Pages/Company'
import { InfomationPage } from './Pages/Infomation'
import { KaitoriPage } from './Pages/Kaitori'
import { DownloadPage } from './Pages/Download'

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_DIR}>  
          <Routes>
            
            <Route path={`/`} element={
                <TopPage></TopPage>
            } />
              <Route path={`/chintais`} element={
                <ChintaisPage></ChintaisPage>
            } />
            <Route path={`/chintais/:id`} element={
                <ChintaisiViewPage></ChintaisiViewPage>
            } />
            <Route path={`/baibais`} element={
                <BaibaisPage></BaibaisPage>
            } />
            <Route path={`/baibais/:id`} element={
                <BaibaiViewPage></BaibaiViewPage>
            } />
            <Route path={`/lands`} element={
                <LandsPage></LandsPage>
            } />
            <Route path={`/lands/:id`} element={
                <LandViewPage></LandViewPage>
            } />
            <Route path={`/company`} element={
                <CompanyPage></CompanyPage>
            } />
            <Route path={`/infomation`} element={
                <InfomationPage></InfomationPage>
            } />
            <Route path={`/kaitori`} element={
                <KaitoriPage></KaitoriPage>
            } />
             <Route path={`/download`} element={
                <DownloadPage></DownloadPage>
            } />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
