import { FieldErrors, UseFormRegister, UseFormWatch, useForm } from "react-hook-form"
import { InfoFromInerface } from "src/Types/form.d"
import { InputForm } from "src/Components/Atoms/Form/input"
import { SelectForm, SelectValues } from "src/Components/Atoms/Form/select"
import TextAraeForm from "src/Components/Atoms/Form/textarea"
import SubmitFrom from "src/Components/Atoms/Form/submit"
import FormTemplate from "src/Components/Templates/formTemplate"
import { Button } from "src/Components/Atoms/Button"

export const InfoFormConfirm =({input , cancel} 
    : { 
        input: InfoFromInerface,
        cancel : () => void
    }) => {




    return (
        <FormTemplate>
        <dl className="formGroup">
            <dt>
                <label className="required">お問い合わせ種別</label>
            </dt>
            <dd className="select">
                { input.category }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label className="required">名前</label>
            </dt>
            <dd className="inputName">
                { input.name1 } { input.name2 }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label className="required">住所</label>
            </dt>
            <dd>
                { input.address }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label className="required">電話番号</label>
            </dt>
            <dd>
                 { input.tel }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label className="required">E-mail</label>
            </dt>
            <dd>
                { input.email }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label>会社名</label>
            </dt>
            <dd>
             { input.company }
            </dd>
        </dl>
        
        <dl className="formGroup">
            <dt>
                <label className="required">問合せ内容<br/>（希望日時等）</label>
            </dt>
            <dd className="textarea">
             { input.info }
            </dd>
        </dl>
        <dl className="formGroup">
            <dt>
                <label className="required">個人情報取り扱い事項</label>
            </dt>
            <dd>
                同意します
            </dd>
        </dl>
        <SubmitFrom
            value="送信"
        ></SubmitFrom>
          <div className="m-t-20 al-c">
                <Button onClick={cancel} color="blue"> ← 戻る</Button>
            </div>
        </FormTemplate>
    )  
}