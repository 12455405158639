import CategoriesTemplate from "src/Components/Templates/CategoriesTemplate"

import CategoryCard from "src/Components/Molecules/Card/categoryCard"
import { useLocation, useNavigate } from "react-router-dom"


export const CategoriesChintai = ({handleUpdate}:{handleUpdate:()=>void}) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    const addQueryCategroy = (cate:string) => {
        const currentUrlParams = new URLSearchParams(location.search); // 現在のURLのクエリパラメータを取得
        currentUrlParams.set('cate', cate); // `sort=price_upper`をセットまたは上書き
    
        // URLを更新 (現在のパスに新しいクエリパラメータを付与)
        navigate(`/chintais?${currentUrlParams.toString()}`);
        handleUpdate()

    }   

    return (
        <CategoriesTemplate>
            <CategoryCard 
                onClick={() => addQueryCategroy('エアコン')}
                img="c_eacon.jpg"
                name="エアコン付き物件"
                dd="エアコン付き物件"
            />
            <CategoryCard 
                onClick={() => addQueryCategroy('駐車場')}
                img="c_parking.jpg"
                name="駐車場付き物件"
                dd="敷地内に駐車場付き物件"
            />
            <CategoryCard 
               onClick={() => addQueryCategroy('ユーミーマンション')}
                img="c_yumi.jpg"
                name="ユーミーマンション"
                dd="ユーミーマンション（カンエイ） の入居者募集中の賃貸物件一覧です。"
            />
            <CategoryCard 
                onClick={() => addQueryCategroy('d-room')}
                img="c_dk.jpg"
                name="D-ROOM"
                dd="ダイワハウスの賃貸の入居者募集中一覧です。"
            />
        </CategoriesTemplate>
    )  
}