import { Link } from "react-router-dom"
import styled from "styled-components"

interface BreadcrumbNavInterface { 
    name: string
    href : string
    active : boolean
}

const BreadcrumbNav = ({items}:{items: BreadcrumbNavInterface[]}) => {

    return (
        <SBreadcrumbNav>
            <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`/`}>ホーム</Link></li>
                {
                    items.map((item, i, )=>(
                        <li className={`breadcrumb-item ${item.active ? 'active' : ''}`} key={`breadcrumb-${i}`}>
                            <Link to={item.href}> {item.name} </Link>
                        </li>
                    ))
                }
            </ul>
        </SBreadcrumbNav>
    )
}

const SBreadcrumbNav = styled.nav`
    .breadcrumb{
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
    .breadcrumb-item {
        margin-right: 0.5rem;
    }
    
    .breadcrumb-item a {
        color: #0275d8;
        text-decoration: none;
    }
    
    .breadcrumb-item a:hover {
        text-decoration: underline;
    }
    
    .breadcrumb-item.active {
        a{
        color: #000;
        pointer-events: none;
        }
    }
    
    .breadcrumb-item::after {
        content: '/';
        margin-left: 0.5rem;
    }
    
    .breadcrumb-item:last-child::after {
        content: '';
    }
`

export default BreadcrumbNav