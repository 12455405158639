import { PropsWithChildren } from "react";
import styled from "styled-components";


const NewsList = ({children}:PropsWithChildren) => {
    
    return (
        <SNewsList>
            {children}
        </SNewsList>
    )
}

const SNewsList = styled.ul`
    li{ 
        a{
            display: block;
            padding: 15px 5px;
        }
        &:nth-child(2n){
            background: ${props => props.theme.colors.grayC};
        }
        span{
            display: inline-block;
        }
        .date{
            font-size: ${props => props.theme.fontSize.newsDate}; 
            margin-right: 10px;
        }
        .title{
            font-size: ${props => props.theme.fontSize.newsTitle}; 
        }
    }
`

export default NewsList