import axios from 'axios'
import { LayoutInterface } from 'src/Types/layout.d';

/**
 * 市町村取得.
 */
export const fetchCities = async (query:string):Promise<AreaInterface> => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/cities.json?${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
}

/**
 * 地区取得.
 */
export const fetchAreas = async (query:string):Promise<AreaInterface>  => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/areas.json?${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
}

/**
 * 学校取得.
 */
export const fetchSchools = async (query:string):Promise<AreaInterface>  => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/schools.json?${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
}

/**
 * 間取り取得.
 */
export const fetchLayouts = async (query:string) : Promise<LayoutInterface> => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/Layouts.json?${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }

}

/**
 * 賃貸カテゴリ取得.
 */
export const fetchChintaiCategoies = async ():Promise<IResCategrory> => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/chintaiCategoies.json`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
}

/**
 * 土地カテゴリ取得.
 */
export const fetchLandCategoies = async () => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/landCategoies.json`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }

}

/**
 * 中古住宅カテゴリ取得.
 */
export const fetchBaibaiCategoies = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/searchParams/baibaiCategories.json`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }

}