import { useEffect, useState } from "react"
import { fetchChintaiCategoies } from "src/Data/fetchSearchParam"
import { UseFormRegister, UseFormWatch } from "react-hook-form"
import { SearchFromInterface } from "src/Types/form.d"
import { Checkbox } from "src/Components/Atoms/Form/checkbox"


export const ChintaiCategorySearchForm = ({register,
    watch} : 
    {register : UseFormRegister<SearchFromInterface>,
       watch : UseFormWatch<SearchFromInterface>
   }) => {

    const [categories, setCategories] = useState<CategoryType[]>()

    useEffect(() => {
        fetchChintaiCategoies().then((res) => {
            if(res.respons === 'success') setCategories(res.data)
        })
    },[])

    return(
        <dl className="formGroup">
            <dd>カテゴリ</dd>
            <dt className="checkboxs">
                { categories && 
                categories.map((item,i) => (
                    <Checkbox
                        register={register}
                        registerName={'categories'}
                        value={item.name}
                        id={item.id}
                        key={item.id}
                        name={item.name}
                    ></Checkbox>
                ))
                }
            </dt> 
        </dl>
    )
}